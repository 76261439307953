export function SET_ROLES (state, data) {
	state.roles = data
}

export function SET_PERMISOS (state, data) {
	state.permisos = data
}

export function SET_ROLES_FIND (state, data) {
	state.rolesFind = data
}
