import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_AJUSTE_PAGINAR,
	URI_AJUSTE_REPORTE,
	URI_AJUSTE_DATOS_AGREGAR,
	URI_REALIZAR_AJUSTE
} from '@/utils/constants'

export function getPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacenID)
		formData.append('usuario', data.usuario)
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', ecode.encode(data.keyword))
    	formData.append('paginate', ecode.encode(JSON.stringify(data)))

		axios.post(URI_AJUSTE_PAGINAR, formData)
		.then(response => {
			commit('SET_PAGINATE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporteAjuste ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('ajusteID', data)
		
		axios.post(URI_AJUSTE_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getDatosAjuste ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('tiposalidaID', 1)
		formData.append('almacenID', data.almacen_id)
		
		axios.post(URI_AJUSTE_DATOS_AGREGAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarAjuste ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : data.usuario)
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.total.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', '')
		formData.append('formapago', '')
		formData.append('validezcotizacion', '')
		formData.append('tiempoentrega', '')
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_REALIZAR_AJUSTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}