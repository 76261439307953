export function reporteVenta (state) {
	return state.reporteVenta
}

export function reporteCompra (state) {
	return state.reporteCompra
}

export function reporteInventario (state) {
	return state.reporteInventario
}

export function reporteInventarioExcel (state) {
	return state.reporteInventarioExcel
}

export function reportediariousuario (state) {
	return state.reportediariousuario
}

export function reportemensual (state) {
	return state.reportemensual
}

export function reportegrafico (state) {
	return state.reportegrafico
}

export function reporteVentaExcel (state) {
	return state.reporteVentaExcel
}

export function reporteCompraExcel (state) {
	return state.reporteCompraExcel
}
