import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_PRINCIPAL_REPORTE_ESCRITORIO,
	URI_PRINCIPAL_REPORTE_GRAFICO,
	URI_PRINCIPAL_REPORTE_DIARIO_USUARIO,
	URI_ESTADISTICA_REPORTE_VENTA,
	URI_ESTADISTICA_REPORTE_COMPRA,
	URI_ESTADISTICA_REPORTE_INVENTARIO,
	URI_ESTADISTICA_REPORTE_INVENTARIO_EXCEL,
	URI_ESTADISTICA_REPORTE_VENTA_EXCEL,
	URI_ESTADISTICA_REPORTE_COMPRA_EXCEL
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getReporteDiarioUsuario ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PRINCIPAL_REPORTE_DIARIO_USUARIO)
		.then(response => {
			commit('SET_REPORTE_DIARIO_USUARIO', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporteMensual ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PRINCIPAL_REPORTE_ESCRITORIO)
		.then(response => {
			commit('SET_REPORTE_MENSUAL', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporteGrafico ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('usuarioID', data.usuarioID)
		
		axios.post(URI_PRINCIPAL_REPORTE_GRAFICO, formData)
		.then(response => {
			commit('SET_REPORTE_GRAFICO', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteVenta ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : ecode.encode(data.usuario))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('clienteID', (data.cliente_id === 'Seleccionar') ? 0 : parseInt(data.cliente_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_VENTA, formData)
		.then(response => {
			commit('SET_REPORTE_VENTA', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteCompra ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuarioID', (data.usuario_id === 'Seleccionar') ? 0 : parseInt(data.usuario_id))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('proveedorID', (data.proveedor_id === 'Seleccionar') ? 0 : parseInt(data.proveedor_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_COMPRA, formData)
		.then(response => {
			commit('SET_REPORTE_COMPRA', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteInventario ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuarioID', (data.usuario_id === 'Seleccionar') ? 0 : parseInt(data.usuario_id))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('proveedorID', (data.proveedor_id === 'Seleccionar') ? 0 : parseInt(data.proveedor_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('estado', ecode.encode(data.estado))
		formData.append('cantidadConsultar', ecode.encode(data.cantidadConsultar))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_INVENTARIO, formData)
		.then(response => {
			commit('SET_REPORTE_INVENTARIO', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteInventarioExcel ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuarioID', (data.usuario_id === 'Seleccionar') ? 0 : parseInt(data.usuario_id))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('proveedorID', (data.proveedor_id === 'Seleccionar') ? 0 : parseInt(data.proveedor_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('estado', ecode.encode(data.estado))
		formData.append('cantidadConsultar', ecode.encode(data.cantidadConsultar))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_INVENTARIO_EXCEL, formData)
		.then(response => {
			commit('SET_REPORTE_INVENTARIO_EXCEL', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteVentaExcel ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? 0 : parseInt(data.usuario))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('clienteID', (data.cliente_id === 'Seleccionar') ? 0 : parseInt(data.cliente_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_VENTA_EXCEL, formData)
		.then(response => {
			commit('SET_REPORTE_VENTA_EXCEL', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function descargarReporteCompraExcel ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (data.almacen_id === 'Seleccionar') ? 0 : parseInt(data.almacen_id))
		formData.append('usuarioID', (data.usuario_id === 'Seleccionar') ? 0 : parseInt(data.usuario_id))
		formData.append('categoriaID', (data.categoria_id === 'Seleccionar') ? 0 : parseInt(data.categoria_id))
		formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : parseInt(data.plancuenta_id))
		formData.append('productoID', (data.producto_id === 'Seleccionar') ? 0 : parseInt(data.producto_id))
		formData.append('proveedorID', (data.proveedor_id === 'Seleccionar') ? 0 : parseInt(data.proveedor_id))
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
		formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('tipo', ecode.encode(data.tipo))
		formData.append('seleccion', ecode.encode(data.seleccion))

		axios.post(URI_ESTADISTICA_REPORTE_COMPRA_EXCEL, formData)
		.then(response => {
			commit('SET_REPORTE_COMPRA_EXCEL', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}