export function usuarios (state) {
	return state.usuarios
}

export function usuariosTemporal (state) {
	return state.usuariosTemporal
}

export function roles (state) {
	return state.roles
}

export function usuariosFind (state) {
	return state.usuariosFind
}
