export function SET_REPORTE (state, data) {
	state.reporte = data
}

export function SET_PORCENTAJE_VENTAS (state, data) {
	state.porcentajeventas = data
}

export function SET_VENTAS (state, data) {
	state.ventas = data
}