import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_PLANCUENTA_LISTAR,
	URI_PLANCUENTA_LISTAR_COMPRA,
	URI_PLANCUENTA_GET_INGRESO,
	URI_PLANCUENTA_GET_EGRESO
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getTipoVentas ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PLANCUENTA_LISTAR)
		.then(response => {
			commit('SET_TIPO_VENTAS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getTipoCompras ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PLANCUENTA_LISTAR_COMPRA)
		.then(response => {
			commit('SET_TIPO_COMPRAS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getTipoIngresos ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PLANCUENTA_GET_INGRESO)
		.then(response => {
			commit('SET_TIPO_INGRESOS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getTipoGastos ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PLANCUENTA_GET_EGRESO)
		.then(response => {
			commit('SET_TIPO_EGRESOS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}