import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/config/store'

import { WebsiteRoute } from '@/modules/Website/router/index'
import { AdminLTERoute } from '@/modules/AdminLTE/router/index'
import { AuthAdminLTERoute } from '@/modules/AuthLTE/router/index'
import { ReporteAdminLTERoute } from '@/modules/ReporteAdminLTE/router/index'

Vue.use(VueRouter)

const routes = [
	// Website
	{
		...WebsiteRoute,
		path: '/'
	},
	// Login
	{
		...AuthAdminLTERoute,
		path: '/panel/controladmin/access/efectivo'
	},
	// Private
	{
		...AdminLTERoute,
		path: '/dashboard'
	},
	{
		...ReporteAdminLTERoute,
		path: '/reporte'
	},
	// Redirection 404
	{
		path: '/:catchAll(.*)*',
		component: () => import('../../modules/Shared/pages/ErrorNotFound.vue')
	}
]

const router = new VueRouter({
  	mode: 'history',
  	routes
})

router.beforeEach((to, from, next) => {
  	const isLogged = store.getters['auth/authenticated']
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!isLogged) {
			next({
				path: '/panel/controladmin/access/efectivo'
			})
		} else {
			next()
		}
	} else if (!to.matched.some(record => record.meta.requiresAuth)) {
		if (isLogged) {
			next({
				path: '/dashboard'
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
