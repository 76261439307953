export const ReporteAdminLTERoute = {
    path: '/reporte',
    name: 'reporte',
    redirect: '/reporte',
    component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
    children: [
        { 
            path: '/reporte/venta/termica/:ventaID', 
            name: 'reporteventatermica', 
            component: () => import(/* webpackChunkName: "VentaTermica" */ '../pages/venta/VentaTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/venta/reporte/carta/:ventaID', 
            name: 'reporteventacarta', 
            component: () => import(/* webpackChunkName: "VentaCarta" */ '../pages/venta/reporteVentaCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/cotizacion/termica/:cotizacionID', 
            name: 'reportecotizaciontermica', 
            component: () => import(/* webpackChunkName: "CotizacionTermica" */ '../pages/cotizacion/CotizacionTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/cotizacion/reporte/carta/:cotizacionID', 
            name: 'reportecotizacioncarta', 
            component: () => import(/* webpackChunkName: "CotizacionCarta" */ '../pages/cotizacion/reporteCotizacionCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/compra/termica/:compraID', 
            name: 'reportecompratermica', 
            component: () => import(/* webpackChunkName: "CompraTermica" */ '../pages/compra/CompraTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/compra/reporte/carta/:compraID', 
            name: 'reportecompracarta', 
            component: () => import(/* webpackChunkName: "CompraCarta" */ '../pages/compra/reporteCompraCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ajuste/termica/:ajusteID', 
            name: 'reporteajustetermica', 
            component: () => import(/* webpackChunkName: "AjusteTermica" */ '../pages/ajuste/AjusteTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ajuste/reporte/carta/:ajusteID', 
            name: 'reporteajustecarta', 
            component: () => import(/* webpackChunkName: "AjusteCarta" */ '../pages/ajuste/reporteAjusteCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ajusteingreso/termica/:ajusteingresoID', 
            name: 'reporteajusteingresotermica', 
            component: () => import(/* webpackChunkName: "AjusteIngresoTermica" */ '../pages/ajusteingreso/AjusteIngresoTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ajusteingreso/reporte/carta/:ajusteingresoID', 
            name: 'reporteajusteingresocarta', 
            component: () => import(/* webpackChunkName: "AjusteIngresoCarta" */ '../pages/ajusteingreso/reporteAjusteIngresoCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/traspaso/termica/:traspasoID', 
            name: 'reportetraspasotermica', 
            component: () => import(/* webpackChunkName: "TraspasoTermica" */ '../pages/traspaso/TraspasoTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/traspaso/reporte/carta/:traspasoID', 
            name: 'reportetraspasocarta', 
            component: () => import(/* webpackChunkName: "TraspasoCarta" */ '../pages/traspaso/reporteTraspasoCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ingresocaja/termica/:ingresocajaID', 
            name: 'reporteingresocajatermica', 
            component: () => import(/* webpackChunkName: "IngresoCajaTermica" */ '../pages/ingresocaja/IngresoCajaTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/ingresocaja/reporte/carta/:ingresocajaID', 
            name: 'reporteingresocajacarta', 
            component: () => import(/* webpackChunkName: "IngresoCajaCarta" */ '../pages/ingresocaja/reporteIngresoCajaCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/salidacaja/termica/:salidacajaID', 
            name: 'reportesalidacajatermica', 
            component: () => import(/* webpackChunkName: "SalidaCajaTermica" */ '../pages/salidacaja/SalidaCajaTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/salidacaja/reporte/carta/:salidacajaID', 
            name: 'reportesalidacajacarta', 
            component: () => import(/* webpackChunkName: "SalidaCajaCarta" */ '../pages/salidacaja/reporteSalidaCajaCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/arqueocaja/termica/:arqueocajaID', 
            name: 'reportearqueocajatermica', 
            component: () => import(/* webpackChunkName: "ArqueoCajaTermica" */ '../pages/arqueocaja/ArqueoCajaTermica.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/arqueocaja/reporte/carta/:arqueocajaID', 
            name: 'reportearqueocajacarta', 
            component: () => import(/* webpackChunkName: "ArqueoCajaCarta" */ '../pages/arqueocaja/reporteArqueoCajaCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/venta/general', 
            name: 'reporteventageneral', 
            component: () => import(/* webpackChunkName: "ReporteVenta" */ '../pages/reporteventa/Reportes.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/compra/general', 
            name: 'reportecomprageneral', 
            component: () => import(/* webpackChunkName: "ReporteCompra" */ '../pages/reportecompra/Reportes.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/inventario/general', 
            name: 'reporteinventariogeneral', 
            component: () => import(/* webpackChunkName: "ReporteInventario" */ '../pages/reporteinventario/Reportes.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/reporte/cuentacobrar/reporte/carta/:cuentacobrarID', 
            name: 'reportecuentacobrarcarta', 
            component: () => import(/* webpackChunkName: "CuentaCobrar" */ '../pages/cuentacobrar/reporteCuentaCobrarCarta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        }
    ]
}