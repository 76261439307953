import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import Cookies from 'js-cookie'
import { 
	URI_PROVEEDOR_LISTAR,
	URI_PROVEEDOR_LISTAR_MASIVO,
	STORAGE_LISTA_SCROLL_PROVEEDOR,
	URI_PROVEEDOR_GUARDAR_POST
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getProveedorListado ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PROVEEDOR_LISTAR)
		.then(response => {
			commit('SET_PROVEEDORES', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export async function getListaScrollMasivoProveedor ({ commit }, data) {
	try {
		const response = await axios.get(URI_PROVEEDOR_LISTAR_MASIVO)
		if (response.status === 200) {
			const lista = response.data
			const nuevalista = lista.map(function (value, index) {
				value.detalle = `${value.empresa}`
				return value
			})

			console.log('cargando proveedores masivos localmente')
			localStorage.setItem(STORAGE_LISTA_SCROLL_PROVEEDOR, JSON.stringify(nuevalista))

			commit('SET_SCROLL_PROVEEDORES', response.data)
			return response
		}
	} catch (error) {
		return error
	}
}

export async function guardarProveedorRapido ({ commit }, data) {
	try {
		const ecode = new EuropioCode()
        const formData = new FormData()
        formData.append('nombre', ecode.encode(data.nombreProveedor))
		const response = await axios.post(URI_PROVEEDOR_GUARDAR_POST, formData)
		if (response.status === 201) {
			return response
		}
	} catch (error) {
		return error
	}
}