import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_SALIDACAJA_PAGINAR,
	URI_SALIDACAJA_GUARDAR,
	URI_SALIDACAJA_REPORTE,
	URI_SALIDACAJA_ELIMINAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)
		formData.append('usuarioID', data.usuario_id)
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', ecode.encode(data.keyword))
    	formData.append('paginate', ecode.encode(JSON.stringify(data)))

		axios.post(URI_SALIDACAJA_PAGINAR, formData)
		.then(response => {
			commit('SET_PAGINATE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporte ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('salidacajaID', data)
		
		axios.post(URI_SALIDACAJA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarSalidaCaja ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('fechaSalida', data.fechaGasto)
		formData.append('plancuentaID', data.plancuentaID)
		formData.append('tipocambioID', data.tipocambioID)
		formData.append('importeSalida', ecode.encode(data.importeGasto.toString()))
		formData.append('detalleSalida', ecode.encode(data.detalleGasto))
		
		axios.post(URI_SALIDACAJA_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteSalidaCaja ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('salidacajaID', id)
		
		axios.post(URI_SALIDACAJA_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}