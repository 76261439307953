export function SET_EMPRESAS (state, data) {
	state.empresas = data
}

export function SET_EMPRESA (state, data) {
	state.empresa = data
}

export function SET_FILE (state, file) {
	state.file = file
}