export const WebsiteRoute = {
    path: '/',
    redirect: '/',
    component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
    children: [
        { 
            path: '', 
            name: 'inicio', 
            component: () => import(/* webpackChunkName: "Inicio" */ '../pages/Inicio/Inicio.vue'),
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        { 
            path: '/producto/:slug',
            name: 'productowebsite', 
            component: () => import(/* webpackChunkName: "ProductoWebsite" */ '../pages/Producto/Producto.vue'),
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        { 
            path: '/privacidad-politicas', 
            name: 'privacidad', 
            component: () => import(/* webpackChunkName: "Inicio" */ '../pages/Privacidad.vue'),
            props: true,
            meta: {
                requiresAuth: false
            }
        }
    ]
}