import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_TIPOSALIDA_LISTAR_ALL,
	URI_TIPOSALIDA_GET_LISTAR_FACTURACION
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function listaTipoSalidaAll ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_TIPOSALIDA_LISTAR_ALL)
		.then(response => {
			commit('SET_TIPO_SALIDAS', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function listaTipoSalidaVenta ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_TIPOSALIDA_GET_LISTAR_FACTURACION)
		.then(response => {
			commit('SET_TIPO_SALIDAS', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}