export function roles (state) {
	return state.roles
}

export function permisos (state) {
	return state.permisos
}

export function rolesFind (state) {
	return state.rolesFind
}
