<template>
	<div>
		<router-view/>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {

		methods: {

		},

		async mounted () {

		}
	}
</script>