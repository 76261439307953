/* eslint-disable no-multi-spaces */
export const API_URL                             = 'http://backend-efectivo.local'
export const MODULO                              = 'api/libreria'
export const TOKEN                               = 'token'
export const USER_ID                             = 'usuarioID'
export const ALMACEN_KEY_ID                      = 'almacenID'
export const ALMACEN_SISTEMA_KEY_ID              = 'SistemaAlmacenID'
export const STORAGE_LISTA_SCROLL_PRODUCTO_STOCK = 'storageListaScrollProductosStock'
export const STORAGE_LISTA_SCROLL_PRODUCTO       = 'storageListaScrollProductos'
export const STORAGE_LISTA_SCROLL_CLIENTE        = 'storageListaScrollClientes'
export const STORAGE_LISTA_SCROLL_PROVEEDOR      = 'storageListaScrollProveedores'
export const SISTEMA_USUARIO                     = 'SistemaUsuario'

/** MODULO PRINCIPAL */
export const URI_CONFIGURACION_DASHBOARD               = '/api/libreria/principal/configuracion'
export const URI_CONFIGURACION_SISTEMA                 = '/api/libreria/principal/sistema'
export const URI_CONFIGURACION_SISTEMA_ENUM_EDITAR     = '/api/libreria/principal/enum/editar'
export const URI_CONFIGURACION_SISTEMA_NUMERICO_EDITAR = '/api/libreria/principal/numerico/editar'
export const URI_CONFIGURACION_SISTEMA_INICIALIZACION  = '/api/libreria/principal/inicializacion'

export const URI_PRINCIPAL_INFORMACION                 = '/api/libreria/principal/informacion-pasos'
export const URI_PRINCIPAL_REPORTE_GRAFICO             = '/api/libreria/principal/reporte-grafico'
export const URI_PRINCIPAL_REPORTE_ESCRITORIO          = '/api/libreria/principal/reporte-escritorio-mensual'
export const URI_PRINCIPAL_TIPO_CAMBIO                 = '/api/libreria/principal/listadotipoCambio'
export const URI_PRINCIPAL_REPORTE_DIARIO_USUARIO      = '/api/libreria/principal/reporte-diario-usuario'

/** MODULO EMPRESA */
export const URI_EMPRESA_LISTAR  = '/api/libreria/empresa/listar'
export const URI_EMPRESA_MOSTRAR = '/api/libreria/empresa/mostrar'
export const URI_EMPRESA_EDITAR = '/api/libreria/empresa/editar'

/** MODULO SUSCRIPCION */
export const URI_SUSCRIPCION = '/api/sistema/suscripcion/lista-suscripcion'

/** MODULO LOG REGISTRO */
export const URI_PRINCIPAL_RESUMEN = '/api/libreria/logregistro/resumen-panel-principal'

/** MODULO PORCENTAJE IMPUESTO */
export const URI_PORCENAJEIMPUESTO_LISTAR = '/api/libreria/porcentajeimpuesto/get-listar-impuesto'

/** MODULO ESTADO */
export const URI_ESTADO_LISTAR = '/api/libreria/estado/get-api-listar'

/** MODULO SOLICITANTE */
export const URI_SOLICITANTE_LISTAR = '/api/libreria/solicitante/get-api-listar-solicitante'

/** MODULO PORCENTAJE VENTA */
export const URI_PORCENTAJEVENTA_LISTAR = '/api/libreria/porcentajeventa/listar'

/** MODULO TIPO CAMBIO */
export const URI_TIPOCAMBIO_GET_CAMBIO = '/api/libreria/tipocambio/get-api-cambio'

/** MODULO VENTA */
export const URI_VENTA_SIN_FACTURA              = '/api/libreria/venta/guardar'
export const URI_EDITAR_VENTA                   = '/api/libreria/venta/editar'
export const URI_VENTA_CON_FACTURA              = '/api/libreria/venta/realizarFactura'
export const URI_VENTA_CREDITO                  = '/api/libreria/venta/guardarCredito'
export const URI_SERVICIO_SIN_FACTURA           = '/api/libreria/venta/guardarServicio'
export const URI_SERVICIO_CON_FACTURA           = '/api/libreria/venta/facturarServicio'
export const URI_SERVICIO_CREDITO               = '/api/libreria/venta/guardarCreditoServicio'
export const URI_VENTA_CONSULTAR_SALIDA_ALMACEN = '/api/libreria/venta/paginar'
export const URI_VENTA_ANULAR                   = '/api/libreria/venta/api-anular-venta'
export const URI_VENTA_CONVERTIR_FACTURA        = '/api/libreria/venta/convertir-venta-factura'
export const URI_VENTA_FACTURA_GLOBAL           = '/api/libreria/venta/obtener-venta-factura-global'
export const URI_VENTA_DATOS_AGREGAR            = '/api/libreria/venta/datos-agregar-venta'
export const URI_VENTA_REPORTE                  = '/api/libreria/venta/reporte'

/** MODULO AJUSTE */
export const URI_REALIZAR_AJUSTE      = '/api/libreria/ajuste/guardarAjuste'
export const URI_AJUSTE_DATOS_AGREGAR = '/api/libreria/ajuste/datos-agregar-ajuste'
export const URI_AJUSTE_PAGINAR       = '/api/libreria/ajuste/paginar'
export const URI_AJUSTE_SEARCH        = '/api/libreria/ajuste/search'
export const URI_AJUSTE_SEARCH_DATE   = '/api/libreria/ajuste/search-date'
export const URI_AJUSTE_REPORTE       = '/api/libreria/ajuste/reporte'

/** MODULO AJUSTE INGRESO */
export const URI_AJUSTEINGRESO_DATOS_AGREGAR = '/api/libreria/ajusteingreso/datos-agregar-ajuste'
export const URI_AJUSTEINGRESO_PAGINAR       = '/api/libreria/ajusteingreso/paginar'
export const URI_AJUSTEINGRESO_SEARCH        = '/api/libreria/ajusteingreso/search'
export const URI_AJUSTEINGRESO_SEARCH_DATE   = '/api/libreria/ajusteingreso/search-date'
export const URI_AJUSTEINGRESO_GUARDAR       = '/api/libreria/ajusteingreso/guardar'
export const URI_AJUSTEINGRESO_REPORTE       = '/api/libreria/ajusteingreso/reporte'

/** MODULO ALMACEN */
export const URI_ALMACEN_LISTAR_LAST  = '/api/libreria/almacen/get-api-listar-last'
export const URI_ALMACEN_GET_LISTAR   = '/api/libreria/almacen/get-api-listar'
export const URI_ALMACEN_GUARDAR_POST = '/api/libreria/almacen/guardarPostAlmacen'
export const URI_ALMACEN_LISTAR_POST  = '/api/libreria/almacen/post-api-listar'
export const URI_ALMACEN_PAGINAR      = '/api/libreria/almacen/paginar'
export const URI_ALMACEN_GUARDAR      = '/api/libreria/almacen/guardar'
export const URI_ALMACEN_EDITAR       = '/api/libreria/almacen/editar'
export const URI_ALMACEN_ELIMINAR     = '/api/libreria/almacen/eliminar'

/** MODULO PROVEEDOR */
export const URI_PROVEEDOR_LISTAR_LAST      = '/api/libreria/proveedor/get-api-listar-last'
export const URI_PROVEEDOR_LISTAR           = '/api/libreria/proveedor/get-api-listar'
export const URI_PROVEEDOR_LISTAR_MASIVO    = '/api/libreria/proveedor/get-api-listar-masivo'
export const URI_PROVEEDOR_LISTAR_NIT       = '/api/libreria/proveedor/get-api-listar-nit'
export const URI_PROVEEDOR_LISTAR_PRODUCTOS = '/api/libreria/proveedor/get-api-listar-productos'
export const URI_PROVEEDOR_BUSCAR           = '/api/libreria/proveedor/api-buscar-proveedor'
export const URI_PROVEEDOR_GUARDAR          = '/api/libreria/proveedor/guardar'
export const URI_PROVEEDOR_ACTUALIZAR       = '/api/libreria/proveedor/actualizar'
export const URI_PROVEEDOR_GUARDAR_POST     = '/api/libreria/proveedor/guardarPostProveedor'
export const URI_PROVEEDOR_LISTADO          = '/api/libreria/proveedor/api-listado-proveedor'

/** MODULO TIPO INGRESO */
export const URI_TIPO_INGRESO_LISTAR     = '/api/libreria/tipoingreso/get-api-listar-buyer'
export const URI_TIPOINGRESO_LISTAR      = '/api/libreria/tipoingreso/get-api-listar'
export const URI_TIPO_INGRESO_GET_LISTAR = '/api/libreria/tipoingreso/get-api-listar-de-ingreso'
export const URI_TIPO_INGRESO_COMPRA     = '/api/libreria/tipoingreso/get-api-listar-compra'

/** MODULO UNIDAD */
export const URI_UNIDAD_LISTAR   = '/api/libreria/unidad/get-api-listar'
export const URI_UNIDAD_GUARDAR  = '/api/libreria/unidad/guardarPostUnidad'
export const URI_UNIDAD_PAGINATE = '/api/libreria/unidad/paginar'
export const URI_UNIDAD_EDITAR   = '/api/libreria/unidad/editar'
export const URI_UNIDAD_ELIMINAR = '/api/libreria/unidad/eliminar'

/** MODULO MARCA */
export const URI_MARCA_LISTAR   = '/api/libreria/marca/get-api-listar'
export const URI_MARCA_GUARDAR  = '/api/libreria/marca/guardarPostMarca'
export const URI_MARCA_EDITAR   = '/api/libreria/marca/editar'
export const URI_MARCA_ELIMINAR = '/api/libreria/marca/eliminar'
export const URI_MARCA_PAGINATE = '/api/libreria/marca/paginar'

/** MODULO PROCEDENCIA */
export const URI_PROCEDENCIA_LISTAR  = '/api/libreria/procedencia/get-api-listar'
export const URI_PROCEDENCIA_GUARDAR = '/api/libreria/procedencia/guardarPostProcedencia'

/** MODULO CATEGORIA */
export const URI_CATEGORIA_LISTAR    = '/api/libreria/categoria/get-api-listar'
export const URI_CATEGORIA_GUARDAR   = '/api/libreria/categoria/guardarPostCategoria'
export const URI_CATEGORIA_EDITAR    = '/api/libreria/categoria/editar'
export const URI_CATEGORIA_ELIMINAR  = '/api/libreria/categoria/eliminar'
export const URI_CATEGORIA_PAGINATE = '/api/libreria/categoria/paginar'

/** MODULO SUBCATEGORIA */
export const URI_SUBCATEGORIA_LISTAR  = '/api/libreria/subcategoria/listar'
export const URI_SUBCATEGORIA_GUARDAR  = '/api/libreria/subcategoria/guardar'

/** MODULO TIPOSALIDA */
export const URI_TIPOSALIDA_LISTAR_ALL             = '/api/libreria/tiposalida/get-api-listar-all-venta'
export const URI_TIPOSALIDA_GET_LISTAR_FACTURACION = '/api/libreria/tiposalida/get-api-listar-venta-facturacion'

/** MODULO PLANCUENTA */
export const URI_PLANCUENTA_LISTAR                 = '/api/libreria/plancuenta/get-api-listar'
export const URI_PLANCUENTA_LISTAR_COMPRA          = '/api/libreria/plancuenta/get-api-listar-compra'
export const URI_PLANCUENTA_LISTAR_CONSULTA_COMPRA = '/api/libreria/plancuenta/get-api-listar-consulta-compra'
export const URI_PLANCUENTA_GET_INGRESO            = '/api/libreria/plancuenta/get-api-ingreso'
export const URI_PLANCUENTA_GET_EGRESO             = '/api/libreria/plancuenta/get-api-egreso'

/** MODULO KARDEX */
export const URI_KARDEX_ACTUALIZAR_COSTO = '/api/libreria/kardex/actualizar-costo'
export const URI_KARDEX_LISTAR           = '/api/libreria/kardex/listar'
export const URI_KARDEX_BUSCAR           = '/api/libreria/kardex/buscar'

/** MODULO PRODUCTO */
export const URI_PRODUCTO_LISTAR_BUYER           = '/api/libreria/producto/get-listar-productos-buyer-scroll'
export const URI_PRODUCTO_BUSCAR_BUYER           = '/api/libreria/producto/buscar-producto-buyer-scroll'
export const URI_PRODUCTO_CAMBIAR_PROVEEDOR      = '/api/libreria/producto/cambiar-proveedor'
export const URI_PRODUCTO_BARCODE                = '/api/libreria/producto/get-barcode'
export const URI_PRODUCTO_CODE                   = '/api/libreria/producto/get-code'
export const URI_PRODUCTO_GUARDAR                = '/api/libreria/producto/guardar-revision'
export const URI_PRODUCTO_EDITAR                 = '/api/libreria/producto/editar-revision'
export const URI_PRODUCTO_ELIMINAR               = '/api/libreria/producto/eliminar-revision'
export const URI_PRODUCTO_REVISION               = '/api/libreria/producto/lista-producto-revision'
export const URI_PRODUCTO_INFORMACION            = '/api/libreria/producto/informacion-producto-proveedor'
export const URI_PRODUCTO_LISTA_PROVEEDOR        = '/api/libreria/producto/listado-producto-proveedor'
export const URI_PRODUCTO_BUSCAR_PROVEEDOR       = '/api/libreria/producto/buscar-producto-proveedor'
export const URI_PRODUCTO_BUSCAR_PROVEEDOR_ID    = '/api/libreria/producto/buscar-producto-proveedor-por-id'
export const URI_PRODUCTO_DESCARGAR_CODIGO_BARRA = '/api/libreria/producto/descargar-codigo-barras'
export const URI_PRODUCTO_LISTA_MASIVA           = '/api/libreria/producto/lista-producto-masiva'
export const URI_PRODUCTO_BUSCAR_SCROLL          = '/api/libreria/producto/buscar-producto-scroll'
export const URI_PRODUCTO_LISTA                  = '/api/libreria/producto/listadoProductos'
export const URI_PRODUCTO_EDITAR_FECHA           = '/api/libreria/producto/editar-fechas-producto'
export const URI_PRODUCTO_ACTUALIZAR_CATEGORIA   = '/api/libreria/producto/actualizar-categoria'
export const URI_PRODUCTO_ACTUALIZAR_MARCA       = '/api/libreria/producto/actualizar-marca'
export const URI_PRODUCTO_ACTUALIZAR_UNIDAD      = '/api/libreria/producto/actualizar-unidad'
export const URI_PRODUCTO_CORREGIR_UNIDAD        = '/api/libreria/producto/correccion-unidad'
export const URI_PRODUCTO_LISTA_MASIVO_SCROLL    = '/api/libreria/producto/listado-masivo-scroll'

export const URI_PRODUCTO_INICIAL                = '/api/libreria/producto/postProductoInicial'
export const URI_PRODUCTO_CODIGO_INICIAL         = '/api/libreria/producto/postProductoCodigoInicial'
export const URI_PRODUCTO_POST_CODIGO_COMPRA     = '/api/libreria/producto/postProductoCodigoCompra'
export const URI_PRODUCTO_POST_COMPRA            = '/api/libreria/producto/postProductoCompra'
export const URI_PRODUCTO_COMPRA                 = '/api/libreria/producto/postProductoCompra'
export const URI_PRODUCTO_POST_TRASPASO          = '/api/libreria/producto/postProductoTraspaso'
export const URI_PRODUCTO_POST_CODIGO_TRASPASO   = '/api/libreria/producto/postProductoCodigoTraspaso'

export const URI_VENTA_POST_PRODUCTO             = '/api/libreria/producto/postProducto'
export const URI_VENTA_POST_CODIGO_PRODUCTO      = '/api/libreria/producto/postProductoCodigo'
export const URI_VENTA_POST_SERVICIO             = '/api/libreria/producto/postServicio'
export const URI_VENTA_POST_CODIGO_SERVICIO      = '/api/libreria/producto/postServicioCodigo'

/** MODULO IMAGEN PRODUCTO */
export const URI_IMAGENPRODUCTO_ELIMINAR = '/api/libreria/imagenproducto/eliminar'
export const URI_IMAGENPRODUCTO_GUARDAR  = '/api/libreria/imagenproducto/guardar'

/** MODULO INVENTARIO */
export const URI_ALMACEN_LISTAR                          = '/api/libreria/inventario/lista-almacen'
export const URI_INVENTARIO_STOCK_CERO                   = '/api/libreria/inventario/stock-cero'
export const URI_INVENTARIO_BUSCAR_STOCK_CERO            = '/api/libreria/inventario/buscar-stock-cero'
export const URI_INVENTARIO_REPORTE_STOCK_CERO           = '/api/libreria/inventario/reporte-stock-cero-excel'
export const URI_INVENTARIO_REPORTE_STOCK_CERO_PDF       = '/api/libreria/inventario/reporte-stock-cero-pdf'
export const URI_INVENTARIO_MOVIMIENTO                   = '/api/libreria/inventario/movimientos'
export const URI_INVENTARIO_BUSCAR_PRODUCTO              = '/api/libreria/inventario/buscarproducto'
export const URI_INVENTARIO_ACTUALIZAR_SALDO             = '/api/libreria/inventario/actualizar-saldos'
export const URI_INVENTARIO_ACTUALIZAR_FECHA_KARDEX      = '/api/libreria/inventario/actualizar-fecha-kardex'
export const URI_INVENTARIO_FORMATEAR                    = '/api/libreria/inventario/formatear-inventario'
export const URI_INVENTARIO_REPORTE_PROVEEDOR            = '/api/libreria/inventario/reporte-inventario-proveedor'
export const URI_INVENTARIO_DESCARGAR_PLANTILLA          = '/api/libreria/inventario/descargar-plantilla'
export const URI_INVENTARIO_DESCARGAR_PLANTILLA_MULTIPLE = '/api/libreria/inventario/descargar-plantilla-multiple'
export const URI_INVENTARIO_IMPORTACION_EXCEL            = '/api/libreria/inventario/importacion-excel'
export const URI_INVENTARIO_IMPORTACION_EXCEL_MULTIPLE   = '/api/libreria/inventario/importacion-excel-multiple'
export const URI_INVENTARIO_ACTUALIZAR_COSTO             = '/api/libreria/inventario/actualizar-costos'
export const URI_INVENTARIO_REPORTE_EXCEL_PROVEEDOR      = '/api/libreria/inventario/reporte-excel-movimiento'
export const URI_INVENTARIO_BUSCAR_MOVIMIENTO            = '/api/libreria/inventario/buscar-movimiento'
export const URI_INVENTARIO_NUEVA_GESTION                = '/api/libreria/inventario/nueva-gestion-inventario'
export const URI_INVENTARIO_SALDO                        = '/api/libreria/inventario/saldos'
export const URI_INVENTARIO_LISTA_PRODUCTO               = '/api/libreria/inventario/listaproducto'
export const URI_INVENTARIO_IMPRIMIR_MOVIMIENTO          = '/libreria/inventario/imprimir-movimiento'

/** MODULO COMPRA */
export const URI_COMPRA_INVENTARIO_INICIAL = '/api/libreria/compra/guardar-inventario-inicial'
export const URI_COMPRA_GUARDAR            = '/api/libreria/compra/guardar-compra'
export const URI_COMPRA_CONSULTAR_INGRESO  = '/api/libreria/compra/consultar-ingresos-por-almacen'
export const URI_COMPRA_BUSCAR             = '/api/libreria/compra/buscar-ingreso'
export const URI_COMPRA_BUSCAR_FECHA       = '/api/libreria/compra/buscar-compra-fecha'
export const URI_COMPRA_ANULAR             = '/api/libreria/compra/api-anular-compra'
export const URI_COMPRA_DATOS              = '/api/libreria/compra/datos-compra'
export const URI_COMPRA_REPORTE            = '/api/libreria/compra/reporte'

/** MODULO CATEGORIA SERVICIO */
export const URI_CATEGORIA_SERVICIO_LISTAR  = '/api/libreria/categoriaservicio/get-api-listar'
export const URI_CATEGORIA_SERVICIO_GUARDAR = '/api/libreria/categoriaservicio/guardar'

/** MODULO SERVICIO */
export const URI_SERVICIO_LISTAR      = '/api/libreria/servicio/get-listar'
export const URI_SERVICIO_GET_CODE    = '/api/libreria/servicio/get-code'
export const URI_SERVICIO_GET_BARCODE = '/api/libreria/servicio/get-barcode'
export const URI_SERVICIO_GUARDAR     = '/api/libreria/servicio/guardar'
export const URI_SERVICIO_EDITAR      = '/api/libreria/servicio/editar'
export const URI_SERVICIO_ELIMINAR    = '/api/libreria/servicio/eliminar'
export const URI_SERVICIO_BUSCAR      = '/api/libreria/servicio/buscar'

/** MODULO TRANSFERENCIA */
export const URI_TRANSFERENCIA_DATO_AGREGAR                = '/api/libreria/transferencia/datos-agregar-transpaso'
export const URI_TRANSFERENCIA_GUARDAR                     = '/api/libreria/transferencia/guardar'
export const URI_TRANSFERENCIA_CONSULTA_PROCESO_ORIGEN     = '/api/libreria/transferencia/consultar-transferencia-proceso-por-almacen-origen'
export const URI_TRANSFERENCIA_BUSCAR_PROCESO_ORIGEN       = '/api/libreria/transferencia/buscar-transferencia-proceso-por-almacen-origen'
export const URI_TRANSFERENCIA_BUSCAR_PROCESO_ORIGEN_FECHA = '/api/libreria/transferencia/buscar-transferencia-proceso-por-almacen-origen-fecha'
export const URI_TRANSFERENCIA_CONFIRMAR                   = '/api/libreria/transferencia/api-confirmar-transferencia'
export const URI_TRANSFERENCIA_ANULAR                      = '/api/libreria/transferencia/api-anular-transferencia'
export const URI_TRANSFERENCIA_REPORTE                     = '/api/libreria/transferencia/reporte'
export const URI_TRANSFERENCIA_DESCARGAR                   = '/libreria/transferencia/descargar-transferencia'

/** MODULO COTIZACION */
export const URI_REALIZAR_COTIZACION       = '/api/libreria/cotizacion/guardar'
export const URI_EDITAR_COTIZACION         = '/api/libreria/cotizacion/editar'
export const URI_COTIZACION_SALIDA_ALMACEN = '/api/libreria/cotizacion/consultar-salidas-por-almacen'
export const URI_COTIZACION_VER_LISTA      = '/libreria/cotizacion/verlista/'
export const URI_COTIZACION_DATOS_AGREGAR  = '/api/libreria/cotizacion/datos-agregar-cotizacion'
export const URI_COTIZACION_REPORTE        = '/api/libreria/cotizacion/reporte'

/** MODULO TIPO PRECIO VENTA */
export const URI_TIPOPRECIOVENTA_LISTAR     = '/api/libreria/tipoprecioventa/get-api-listar'
export const URI_TIPOPRECIOVENTA_PAGINAR    = '/api/libreria/tipoprecioventa/paginar'
export const URI_TIPOPRECIOVENTA_GUARDAR    = '/api/libreria/tipoprecioventa/guardar'
export const URI_TIPOPRECIOVENTA_ACTUALIZAR = '/api/libreria/tipoprecioventa/editar'
export const URI_TIPOPRECIOVENTA_ELIMINAR   = '/api/libreria/tipoprecioventa/eliminar'

/** MODULO PRECIO VENTA */
export const URI_PRECIOVENTA_AJUSTE_GLOBAL     = '/api/libreria/precioventa/ajuste-precios'
export const URI_PRECIOVENTA_GENERAR           = '/api/libreria/precioventa/generar-precioventa'
export const URI_PRECIOVENTA_ELIMINAR_GLOBAL   = '/api/libreria/precioventa/eliminar-precioventa'
export const URI_PRECIOVENTA_GUARDAR           = '/api/libreria/precioventa/guardar-precioventa'
export const URI_PRECIOVENTA_LISTAR            = '/api/libreria/precioventa/list-precios-venta'
export const URI_PRECIOVENTA_ELIMINAR          = '/api/libreria/precioventa/eliminar'
export const URI_PRECIOVENTA_EDITAR            = '/api/libreria/precioventa/editar-precioventa'
export const URI_PRECIOVENTA_ACTUALIZAR_ESTADO = '/api/libreria/precioventa/actualizar-estado'

/** MODULO TIPO CLIENTE */
export const URI_TIPOCLIENTE_LISTAR  = '/api/libreria/tipocliente/get-api-listar'
export const URI_TIPOCLIENTE_GUARDAR = '/api/libreria/tipocliente/guardar'

/** MODULO CLIENTE */
export const URI_CLIENTE_LISTAR        = '/api/libreria/cliente/get-api-listar'
export const URI_CLIENTE_LISTAR_NIT    = '/api/libreria/cliente/get-api-listar-nit'
export const URI_CLIENTE_ELIMINAR      = '/api/libreria/cliente/eliminar'
export const URI_CLIENTE_BUSCAR        = '/api/libreria/cliente/buscarCliente'
export const URI_CLIENTE_REPORTE_EXCEL = '/api/libreria/cliente/reporte-excel-cliente'
export const URI_CLIENTE_BUSCAR_SCROLL = '/api/libreria/cliente/buscar-cliente-scroll'
export const URI_CLIENTE_MOSTRAR       = '/api/libreria/cliente/mostrar'
export const URI_CLIENTE_LISTAR_MASIVO = '/api/libreria/cliente/get-api-listar-masivo'
export const URI_CLIENTE_UNICO         = '/api/libreria/cliente/unico-cliente'
export const URI_CLIENTE_OBTENER       = '/api/libreria/cliente/obtener-cliente'
export const URI_CLIENTE_PAGINAR       = '/api/libreria/cliente/get-listar'
export const URI_CLIENTE_GUARDAR       = '/api/libreria/cliente/guardar'
export const URI_CLIENTE_EDITAR        = '/api/libreria/cliente/editar'

/** MODULO ARQUEO CAJA */
export const URI_ARQUEOCAJA_VERIFICAR     = '/api/libreria/arqueocaja/verificar-caja'
export const URI_ARQUEOCAJA_ABRIR         = '/api/libreria/arqueocaja/abrir-caja'
export const URI_ARQUEOCAJA_PAGINAR       = '/api/libreria/arqueocaja/paginar'
export const URI_ARQUEOCAJA_CONTROL       = '/api/libreria/arqueocaja/control-caja'
export const URI_ARQUEOCAJA_EDITAR_CIERRE = '/api/libreria/arqueocaja/editar-fecha-cierre'
export const URI_ARQUEOCAJA_CIERRE        = '/api/libreria/arqueocaja/cerrar-caja'
export const URI_ARQUEOCAJA_REPORTE       = '/api/libreria/arqueocaja/reporte'

/** MODULO CUENTA PAGAR */
export const URI_CUENTAPAGAR_GUARDAR       = '/api/libreria/cuentapagar/guardar'
export const URI_CUENTAPAGAR_LISTAR_COBRAR = '/api/libreria/cuentapagar/lista-cuenta-pagar'
export const URI_CUENTAPAGAR_LISTAR        = '/api/libreria/cuentapagar/lista-pagar'

/** MODULO CUENTA COBRAR */
export const URI_CUENTACOBRAR_GUARDAR       = '/api/libreria/cuentacobrar/guardar'
export const URI_CUENTACOBRAR_LISTAR_COBRAR = '/api/libreria/cuentacobrar/lista-cuenta-cobrar'
export const URI_CUENTACOBRAR_LISTAR        = '/api/libreria/cuentacobrar/lista-cobrar'
export const URI_CUENTACOBRAR_REPORTE       = '/api/libreria/cuentacobrar/reporte'

/** MODULO INGRESO CAJA */
export const URI_INGRESOCAJA_PAGINAR      = '/api/libreria/ingresocaja/paginar'
export const URI_INGRESOCAJA_BUSCAR_FECHA = '/api/libreria/ingresocaja/buscar-por-fecha'
export const URI_INGRESOCAJA_BUSCAR       = '/api/libreria/ingresocaja/buscar'
export const URI_INGRESOCAJA_ELIMINAR     = '/api/libreria/ingresocaja/eliminar'
export const URI_INGRESOCAJA_GUARDAR      = '/api/libreria/ingresocaja/guardar'
export const URI_INGRESOCAJA_REPORTE      = '/api/libreria/ingresocaja/reporte'

/** MODULO SALIDA CAJA */
export const URI_SALIDACAJA_PAGINAR      = '/api/libreria/salidacaja/paginar'
export const URI_SALIDACAJA_BUSCAR_FECHA = '/api/libreria/salidacaja/buscar-por-fecha'
export const URI_SALIDACAJA_BUSCAR       = '/api/libreria/salidacaja/buscar'
export const URI_SALIDACAJA_ELIMINAR     = '/api/libreria/salidacaja/eliminar'
export const URI_SALIDACAJA_GUARDAR      = '/api/libreria/salidacaja/guardar'
export const URI_SALIDACAJA_REPORTE      = '/api/libreria/salidacaja/reporte'

/** MODULO USUARIO */
export const URI_LOGIN                         = '/api/libreria/usuario/login'
export const URI_REFRESH                       = '/api/libreria/usuario/refresh'
export const URI_LOGOUT                        = '/api/libreria/usuario/logout'
export const URI_USUARIO_REPORTAR              = '/api/libreria/usuario/reportar'
export const URI_USUARIO_MOSTRAR               = '/api/libreria/usuario/mostrar'
export const URI_USUARIO_PERMISOS              = '/api/libreria/usuario/get-api-usuario-permisos'
export const URI_USUARIO_LISTAR                = '/api/libreria/usuario/get-api-listar'
export const URI_USUARIO_GUARDAR               = '/api/libreria/usuario/guardar'
export const URI_USUARIO_ACTUALIZAR            = '/api/libreria/usuario/actualizar'
export const URI_USUARIO_ELIMINAR              = '/api/libreria/usuario/eliminar'
export const URI_USUARIO_PAGINAR               = '/api/libreria/usuario/paginar'
export const URI_USUARIO_PAGINAR_TEMPORAL      = '/api/libreria/usuario/paginar/temp'
export const URI_USUARIO_TEMPORAL_REESTABLECER = '/api/libreria/usuario/reestablecer'
export const URI_USUARIO_ELIMINAR_PERMANENTE   = '/api/libreria/usuario/eliminar/permanente'

export const URI_ESTADISTICA_REPORTE_VENTA            = '/api/libreria/estadistica/reporte/venta'
export const URI_ESTADISTICA_REPORTE_COMPRA           = '/api/libreria/estadistica/reporte/compra'
export const URI_ESTADISTICA_REPORTE_INVENTARIO       = '/api/libreria/estadistica/reporte/inventario'
export const URI_ESTADISTICA_REPORTE_INVENTARIO_EXCEL = '/api/libreria/estadistica/reporte/inventario/excel'
export const URI_ESTADISTICA_REPORTE_VENTA_EXCEL      = '/api/libreria/estadistica/reporte/venta/excel'
export const URI_ESTADISTICA_REPORTE_COMPRA_EXCEL     = '/api/libreria/estadistica/reporte/compra/excel'

/** MODULO ROLES */
export const URI_ROLE_PAGINAR    = '/api/libreria/role/paginar'
export const URI_ROLE_LISTAR     = '/api/libreria/role/listar'
export const URI_ROLE_GUARDAR    = '/api/libreria/role/guardar'
export const URI_ROLE_ACTUALIZAR = '/api/libreria/role/actualizar'
export const URI_ROLE_ELIMINAR   = '/api/libreria/role/eliminar'

/** MODULO PERMISOS */
export const URI_PERMISO_LISTAR = '/api/libreria/permiso/listar'

export const URI_BACKUP_FORMATEAR = '/api/libreria/backup/formatear-sistema'

/** WEBSITE */
export const URI_WEBSITE_PRODUCTO_LISTAR  = '/api/ecommerce/producto/listar'
export const URI_WEBSITE_ALMACEN_LISTAR   = '/api/ecommerce/almacen/listar'
export const URI_WEBSITE_EMPRESA_MOSTRAR  = '/api/ecommerce/empresa/mostrar'
export const URI_WEBSITE_CATEGORIA_LISTAR = '/api/ecommerce/categoria/listar'
export const URI_WEBSITE_MARCA_LISTAR     = '/api/ecommerce/marca/listar'
export const URI_WEBSITE_ALMACEN_MOSTRAR  = '/api/ecommerce/almacen/mostrar'