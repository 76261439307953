import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_CLIENTE_LISTAR_MASIVO,
	STORAGE_LISTA_SCROLL_CLIENTE
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export async function getListaScrollMasivoCliente ({ commit }, data) {
	try {
		const response = await axios.get(URI_CLIENTE_LISTAR_MASIVO)
		if (response.status === 200) {
			const lista = response.data
			const nuevalista = lista.map(function (value, index) {
				value.detalle = `${value.nitFactura} ${value.nombreFactura}`
				return value
			})

			console.log('cargando clientes masivos localmente')
			localStorage.setItem(STORAGE_LISTA_SCROLL_CLIENTE, JSON.stringify(nuevalista))

			commit('SET_SCROLL_CLIENTES', response.data)
			return response
		}
	} catch (error) {
		return error
	}
}