import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import Cookies from 'js-cookie'
import { 
	ALMACEN_KEY_ID,
	URI_ALMACEN_PAGINAR,
	URI_ALMACEN_GUARDAR,
	URI_ALMACEN_EDITAR,
	URI_ALMACEN_ELIMINAR,
	URI_ALMACEN_LISTAR,
	URI_ALMACEN_GET_LISTAR,
	URI_WEBSITE_ALMACEN_LISTAR,
	URI_WEBSITE_ALMACEN_MOSTRAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getAlmacenes ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_ALMACEN_PAGINAR, formData)
		.then(response => {
			commit('SET_ALMACENES', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getAlmacenListar ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_ALMACEN_LISTAR)
		.then(response => {
			commit('SET_ALMACENES', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getAlmacenListado ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_ALMACEN_GET_LISTAR)
		.then(response => {
			commit('SET_ALMACENES', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function createAlmacen ({ dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombre))
    	formData.append('encargado', ecode.encode(data.encargado))
    	formData.append('direccion', ecode.encode(data.direccion))
    	formData.append('telefono', data.telefono)
    	formData.append('correo', ecode.encode(data.correo))
    	formData.append('horario', ecode.encode(data.horario))
    	formData.append('ciudad', ecode.encode(data.ciudad))

		axios.post(URI_ALMACEN_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function updateAlmacen ({ dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('almacenID', data.id)
        formData.append('nombre', ecode.encode(data.nombre))
    	formData.append('encargado', ecode.encode(data.encargado))
    	formData.append('direccion', ecode.encode(data.direccion))
    	formData.append('telefono', data.telefono)
    	formData.append('correo', ecode.encode(data.correo))
    	formData.append('horario', ecode.encode(data.horario))
    	formData.append('ciudad', ecode.encode(data.ciudad))

		axios.post(URI_ALMACEN_EDITAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteAlmacen ({ dispatch, commit }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('almacenID', id)
		
		axios.post(URI_ALMACEN_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

/** WEBSITE */

export async function checkAlmacenMain ({ commit }) {
	try {
		const formData = new FormData()
		formData.append('almacenID', Cookies.get(ALMACEN_KEY_ID))
		
		const response = await axios.post(URI_WEBSITE_ALMACEN_MOSTRAR, formData)
		if (response.status === 200) {
		 	commit('SET_ALMACEN', response.data.data)	
			return response
		}
	} catch (error) {
		commit('SET_ALMACEN', null)	
		return error
	}
}

export function getListaAlmacen ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_WEBSITE_ALMACEN_LISTAR)
		.then(response => {
			const almacenes = response.data.data
			commit('SET_LISTA_ALMACENES', almacenes)

			if (Cookies.get(ALMACEN_KEY_ID)) {
				dispatch('checkAlmacenMain')
			} else {
				const almacen = almacenes.filter(x => typeof x !== 'undefined').shift()
				Cookies.set(ALMACEN_KEY_ID, almacen.almacen_id, { sameSite: 'Strict', secure: false })
				dispatch('checkAlmacenMain')
			}
			resolve(response)
		}).catch(error => reject(error))
	})
}