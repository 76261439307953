export function SET_PLANTILLA_IMPORTACION (state, data) {
	state.plantillaImportacion = data
}

export function SET_INVENTARIOS (state, data) {
	state.inventarios = data
}

export function SET_INFORMACION_SALDO (state, data) {
	state.informacionSaldo = data
}