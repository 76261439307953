import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_COTIZACION_REPORTE,
	URI_COTIZACION_DATOS_AGREGAR,
	URI_REALIZAR_COTIZACION,
	URI_COTIZACION_SALIDA_ALMACEN,
	URI_EDITAR_COTIZACION
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginacion ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (parseInt(data.almacen_id) > 0) ? data.almacen_id : data.almacenID)
        formData.append('usuario', (data.onlyusuario === 'Seleccionar') ? '' : data.onlyusuario)
        formData.append('clienteID', (data.cliente_id === 'Seleccionar') ? '' : data.cliente_id)
        formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', (data.keyword === '') ? '' : ecode.encode(data.keyword))
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_COTIZACION_SALIDA_ALMACEN, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_COTIZACIONES', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getReporteCotizacion ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('cotizacionID', parseInt(data))
		
		axios.post(URI_COTIZACION_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getDatosCotizacion ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('tiposalidaID', 4)
		formData.append('almacenID', data.almacen_id)
		
		axios.post(URI_COTIZACION_DATOS_AGREGAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarCotizacion ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : data.usuario)
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.total.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', ecode.encode(data.cargoextra.toString()))
		formData.append('formapago', ecode.encode(data.formapago.toString()))
		formData.append('validezcotizacion', ecode.encode(data.validezcotizacion.toString()))
		formData.append('tiempoentrega', ecode.encode(data.tiempoentrega.toString()))
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_REALIZAR_COTIZACION, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function actualizarCotizacion ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('cotizacionID', parseInt(data.cotizacionID))
		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : data.usuario)
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.total.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', ecode.encode(data.cargoextra.toString()))
		formData.append('formapago', ecode.encode(data.formapago.toString()))
		formData.append('validezcotizacion', ecode.encode(data.validezcotizacion.toString()))
		formData.append('tiempoentrega', ecode.encode(data.tiempoentrega.toString()))
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_EDITAR_COTIZACION, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}