import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_VENTA_REPORTE,
	URI_VENTA_DATOS_AGREGAR,
	URI_PORCENTAJEVENTA_LISTAR,
	URI_VENTA_SIN_FACTURA,
	URI_VENTA_CREDITO,
	URI_EDITAR_VENTA,
	URI_VENTA_CONSULTAR_SALIDA_ALMACEN
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginacion ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', (parseInt(data.almacen_id) > 0) ? data.almacen_id : data.almacenID)
        formData.append('usuario', (data.onlyusuario === 'Seleccionar') ? '' : data.onlyusuario)
        formData.append('tiposalidaID', (data.tiposalida_id === 'Seleccionar') ? 0 : data.tiposalida_id)
        formData.append('plancuentaID', (data.plancuenta_id === 'Seleccionar') ? 0 : data.plancuenta_id)
        formData.append('clienteID', (data.cliente_id === 'Seleccionar') ? '' : data.cliente_id)
        formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', (data.keyword === '') ? '' : ecode.encode(data.keyword))
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_VENTA_CONSULTAR_SALIDA_ALMACEN, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_VENTAS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getPorcentajeVenta ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PORCENTAJEVENTA_LISTAR)
		.then(response => {
			commit('SET_PORCENTAJE_VENTAS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporteVenta ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('ventaID', data)
		
		axios.post(URI_VENTA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getDatosVenta ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('tiposalidaID', 1)
		formData.append('almacenID', data.almacen_id)
		
		axios.post(URI_VENTA_DATOS_AGREGAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarVenta ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : ecode.encode(data.usuario))
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuentoTotal.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.totalAPagar.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', '')
		formData.append('formapago', '')
		formData.append('validezcotizacion', '')
		formData.append('tiempoentrega', '')
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_VENTA_SIN_FACTURA, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarVentaCredito ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : ecode.encode(data.usuario))
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.total.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', '')
		formData.append('formapago', '')
		formData.append('validezcotizacion', '')
		formData.append('tiempoentrega', '')
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_VENTA_CREDITO, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function editarVenta ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('venta_id', parseInt(data.venta_id))
		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tiposalida_id', parseInt(data.tiposalida_id))
		formData.append('plancuenta_id', parseInt(data.tipo_venta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : ecode.encode(data.usuario))
		formData.append('clienteNIT', parseInt(data.clienteNit))
		formData.append('tipocambio', 0)

		formData.append('clienteNombre', ecode.encode(data.clienteNombre))
		formData.append('responsable', '')
		formData.append('solicitantenombre', 'SIN NOMBRE')
		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fechaVentaElegido', ecode.encode(data.fechadeventa))
		formData.append('codigoSolicitante', '')
								
		if (this.habilitardescuentoporcentajeventa === 'ACTIVADO') {
			formData.append('descuentoParcial', 0)
			formData.append('descuentoPorcentaje', 0)
			formData.append('porcentajeManual', '')
			formData.append('porcentajeLista', 'No seleccionado')
			formData.append('estadoPorcentaje', 'NO INGRESADO')
		}

		formData.append('montoTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('descuentoPorcentajeTotal', ecode.encode(data.descuento.toString()))
		formData.append('totalVenta', ecode.encode(data.total.toString()))
		formData.append('efectivo', ecode.encode(data.importePagar.toString()))
		formData.append('cambio', ecode.encode(data.cambio.toString()))
		formData.append('totalBaseVentaMoneda', ecode.encode(data.total.toString()))

		formData.append('cargoextra', '')
		formData.append('formapago', '')
		formData.append('validezcotizacion', '')
		formData.append('tiempoentrega', '')
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}

		if (this.TotalTipoCambio > 0) {
			formData.append('totalVentaCambioMoneda', [])
		}
		
		axios.post(URI_EDITAR_VENTA, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}
