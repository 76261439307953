export const AuthAdminLTERoute = {
    path: '/panel/controladmin/access/efectivo',
    name: 'auth',
    redirect: '/panel/controladmin/access/efectivo',
    component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
    children: [
        { 
            path: '', 
            name: 'login', 
            component: () => import(/* webpackChunkName: "Login" */ '../pages/Login.vue'),
            meta: {
                requiresAuth: false
            }
        }
    ]
}