import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import usuario from './modules/usuario'
import role from './modules/role'
import producto from './modules/producto'
import empresa from './modules/empresa'
import almacen from './modules/almacen'
import categoria from './modules/categoria'
import subcategoria from './modules/subcategoria'
import precioventa from './modules/precioventa'
import marca from './modules/marca'
import unidad from './modules/unidad'
import tiposalida from './modules/tiposalida'
import arqueocaja from './modules/arqueocaja'
import inventario from './modules/inventario'
import proveedor from './modules/proveedor'
import transferencia from './modules/transferencia'
import venta from './modules/venta'
import backup from './modules/backup'
import configuracion from './modules/configuracion'
import seguridad from './modules/seguridad'
import estadistica from './modules/estadistica'
import compra from './modules/compra'
import tipoprecioventa from './modules/tipoprecioventa'
import plancuenta from './modules/plancuenta'
import cliente from './modules/cliente'
import cotizacion from './modules/cotizacion'
import ajuste from './modules/ajuste'
import ajusteingreso from './modules/ajusteingreso'
import tipocambio from './modules/tipocambio'
import ingresocaja from './modules/ingresocaja'
import salidacaja from './modules/salidacaja'
import cuentacobrar from './modules/cuentacobrar'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		usuario,
		role,
		producto,
		empresa,
		almacen,
		categoria,
		subcategoria,
		precioventa,
		marca,
		unidad,
		tiposalida,
		arqueocaja,
		inventario,
		proveedor,
		transferencia,
		venta,
		backup,
		configuracion,
		seguridad,
		estadistica,
		compra,
		tipoprecioventa,
		plancuenta,
		cliente,
		cotizacion,
		ajuste,
		ajusteingreso,
		tipocambio,
		ingresocaja,
		salidacaja,
		cuentacobrar
	},
	strict: true
})
