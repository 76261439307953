import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_WEBSITE_PRODUCTO_LISTAR,
	URI_PRODUCTO_REVISION,
	URI_PRODUCTO_BUSCAR_REVISION,
	URI_PRODUCTO_ACTUALIZAR_CATEGORIA,
	URI_PRODUCTO_ACTUALIZAR_MARCA,
	URI_PRODUCTO_ACTUALIZAR_UNIDAD,
	URI_PRODUCTO_CORREGIR_UNIDAD,
	URI_PRODUCTO_LISTA_MASIVO_SCROLL,
	URI_PRODUCTO_LISTA,
	STORAGE_LISTA_SCROLL_PRODUCTO,
	STORAGE_LISTA_SCROLL_PRODUCTO_STOCK,
	URI_PRODUCTO_LISTA_PROVEEDOR
} from '@/utils/constants'
import * as utils from '@/utils/utils'
import * as database from '@/utils/database'

export function getListaProducto ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacenID)
        formData.append('proveedorID', (data.proveedorID === 'Proveedor') ? 0 : data.proveedorID)
		formData.append('keyword', (data.keyword === '') ? '' : ecode.encode(data.keyword))
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_PRODUCTO_REVISION, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_LISTA_PRODUCTOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getProductoProveedor ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('almacenID', data.almacenID)
		formData.append('proveedorID', (data.proveedorID === 'Seleccionar') ? 0 : data.proveedorID)
		formData.append('keyword', ecode.encode(data.keyword))
		formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))
		
		axios.post(URI_PRODUCTO_LISTA_PROVEEDOR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			setTimeout(() => {
				commit('SET_LISTA_PRODUCTO_PROVEEDOR', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export async function getListaScrollMasivoProducto ({ commit }, data) {
	try {
		database.removeData(STORAGE_LISTA_SCROLL_PRODUCTO)
		localStorage.removeItem('cargaDeProductosSinStock')

		const formData = new FormData()
		formData.append('almacenID', data.almacen_id)
		formData.append('proveedorID', (data.proveedor_id === 'Seleccionar' || data.proveedor_id === '' || data.proveedor_id === undefined) ? '' : data.proveedor_id)
		
		const response = await axios.post(URI_PRODUCTO_LISTA_MASIVO_SCROLL, formData)
		if (response.status === 200) {
			const lista = response.data.data
			const nuevalista = lista.map(function (value, index) {
				value.detalle = `${value.codigo} ${value.marca} ${value.nombre} ${value.medida}`
				return value
			})
			database.setData(STORAGE_LISTA_SCROLL_PRODUCTO, nuevalista)
			localStorage.setItem('cargaDeProductosSinStock', 'CARGADO')
			return response
		}
	} catch (error) {
		return error
	}
}

export async function getListaScrollMasivoProductoStock ({ commit }, data) {
	try {
		database.removeData(STORAGE_LISTA_SCROLL_PRODUCTO_STOCK)
		localStorage.removeItem('cargaDeProductosConStock')

		const formData = new FormData()
		formData.append('almacenID', data.almacen_id)

		const response = await axios.post(URI_PRODUCTO_LISTA, formData)
		if (response.status === 200) {
			const lista = response.data.data
			const nuevalista = lista.map(function (value, index) {
				value.detalle = `${value.codigo} ${value.marca} ${value.nombre} ${value.medida}`
				return value
			})
			database.setData(STORAGE_LISTA_SCROLL_PRODUCTO_STOCK, nuevalista)
			localStorage.setItem('cargaDeProductosConStock', 'CARGADO')
			return response
		}
	} catch (error) {
		return error
	}
}

export function updateCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('productoID', data.productoID)
		formData.append('categoriaID', data.categoriaID)
		formData.append('subcategoriaID', data.subcategoriaID)

		axios.post(URI_PRODUCTO_ACTUALIZAR_CATEGORIA, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_LISTA_PRODUCTOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function updateMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('productoID', data.productoID)
		formData.append('marcaID', data.marcaID)

		axios.post(URI_PRODUCTO_ACTUALIZAR_MARCA, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_LISTA_PRODUCTOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function updateUnidad ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('productoID', data.productoID)
		formData.append('unidadID', data.unidadID)

		axios.post(URI_PRODUCTO_ACTUALIZAR_UNIDAD, formData)
		.then(response => {
			// commit('SET_LISTA_PRODUCTOS', [])
			setTimeout(() => {
				commit('SET_LISTA_PRODUCTOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getProductos ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacenID)
        formData.append('marcaID', (data.marcaID === 'Seleccionar') ? 0 : data.marcaID)
		formData.append('categoriaID', (data.categoriaID === 'Seleccionar') ? 0 : data.categoriaID)
		formData.append('subcategoriaID', (data.subcategoriaID === 'Seleccionar') ? 0 : data.subcategoriaID)
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_WEBSITE_PRODUCTO_LISTAR, formData)
		.then(response => {
			// commit('SET_LISTA_PRODUCTOS', [])
			setTimeout(() => {
				commit('SET_PRODUCTOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getCorreccionUnidad ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PRODUCTO_CORREGIR_UNIDAD)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}