import axios from '../../../axios/axios'
import Cookies from 'js-cookie'
import router from '@/config/router'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_LOGIN,
	URI_REFRESH,
	URI_LOGOUT,
	URI_USUARIO_MOSTRAR,
	URI_USUARIO_REPORTAR,
	TOKEN, 
	USER_ID,
	ALMACEN_SISTEMA_KEY_ID,
	SISTEMA_USUARIO
} from '@/utils/constants'

export async function signIn ({ dispatch, commit }, credenciales) {
	const formData = new FormData()
	formData.append('email', credenciales.email)
	formData.append('password', credenciales.password)

	const response = await axios.post(URI_LOGIN, formData)
	if (response.status === 201) {
		const token = response.data.access_token
		dispatch('attempt', token)
	}
}

export async function attempt ({ commit, state, dispatch }, token) {
	if (token) {
		const arrTokenSplit = token.split('.')
		const arrTokenData = JSON.parse(atob(arrTokenSplit[1]))
		const userid = arrTokenData.sub
		const minutes = new Date(arrTokenData.exp * 1000)

		const secure = {
			arrTokenSplit: arrTokenSplit,
			arrTokenData: arrTokenData,
			userid: userid,
			minutes: minutes
		}

		if (process.env.NODE_ENV === 'production') {
			Cookies.set(TOKEN, token, {
				expires: secure.minutes, // Establecer la fecha de expiración según tus necesidades
				sameSite: 'Lax', // 'Lax' o 'Strict' dependiendo de tus requisitos
				secure: true // true  | Solo enviar la cookie sobre conexiones seguras (HTTPS)
			})
		} else {
			Cookies.set(TOKEN, token, { 
				expires: secure.minutes, 
				sameSite: 'Lax', 
				secure: false
			})
		}

		Cookies.set(USER_ID, userid)
		sessionStorage.setItem(TOKEN, token)
		
		commit('SET_TOKEN', token)
		dispatch('checkUserMain')
	} else if (!state.token) return false
}

export async function verifiedAttempt ({ commit, state, dispatch }, token) {
	if (token) {
		commit('SET_TOKEN', token)
	} else if (!state.token) return false
}

export async function checkUserMain ({ commit, dispatch }) {
	try {
		const formData = new FormData()
		formData.append('usuarioID', Cookies.get(USER_ID))
			
		const response = await axios.post(URI_USUARIO_MOSTRAR, formData)
		if (response.status === 200) {
			const usuario = response.data.data
			Cookies.set(ALMACEN_SISTEMA_KEY_ID, usuario.almacenID)
			Cookies.set(SISTEMA_USUARIO, usuario.usuario)
			const color = (usuario.colorEmpresa) ? usuario.colorEmpresa : '#014694'
			localStorage.setItem('colorBaseSistema', color)
			document.documentElement.style.setProperty('--element', color)
			commit('SET_USER', response.data.data)
			return response
		}
	} catch (error) {
		return error
	}
}

export async function reportarData ({ dispatch, commit }, data) {
	const formData = new FormData()
	const ecode = new EuropioCode()
	formData.append('usuarioID', Cookies.get(USER_ID) ? ecode.encode('Existe UsuarioID Cookie') : ecode.encode('No existe usuarioID Cookie'))
	formData.append('tokenCookie', Cookies.get(TOKEN) ? ecode.encode('Existe Token Cookie') : ecode.encode('No existe Token Cookie'))
	formData.append('tokenSession', sessionStorage.getItem(TOKEN) ? ecode.encode('Existe Token Session') : ecode.encode('No existe Token Session'))
	formData.append('hostname', ecode.encode(window.location.hostname))
	formData.append('mensaje', ecode.encode(data))

	const response = await axios.post(URI_USUARIO_REPORTAR, formData)
	if (response.status === 200) {
		return response
	}
}

export function logout ({ dispatch, commit }) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('id', Cookies.get(USER_ID))

		axios.post(URI_LOGOUT, formData)
		.then(response => {
			resolve(response)
			Cookies.remove(ALMACEN_SISTEMA_KEY_ID)
			Cookies.remove(SISTEMA_USUARIO)
			Cookies.remove(USER_ID)
			Cookies.remove(TOKEN)
			sessionStorage.removeItem(TOKEN)
			window.location.href = '/panel/controladmin/access/efectivo'
		})
		.catch(error => reject(error))
	})
}
