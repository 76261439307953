import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_UNIDAD_LISTAR,
	URI_UNIDAD_GUARDAR,
	URI_UNIDAD_EDITAR,
	URI_UNIDAD_ELIMINAR,
	URI_UNIDAD_PAGINATE
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginateUnidad ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_UNIDAD_PAGINATE, formData)
		.then(response => {
			commit('SET_UNIDADES', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getUnidades ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_UNIDAD_LISTAR)
		.then(response => {
			commit('SET_UNIDADES', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarUnidad ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombreUnidad))

		axios.post(URI_UNIDAD_GUARDAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function editarUnidad ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('unidadID', data.id)
		formData.append('nombre', ecode.encode(data.nombreUnidad))

		axios.post(URI_UNIDAD_EDITAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteUnidad ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('unidadID', id)
		
		axios.post(URI_UNIDAD_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}