export function authenticated (state) {
  	return state.token != null
}

export function user (state) {
  	return state.user
}

export function status (state) {
	return state.status != null
}
