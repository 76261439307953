import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_EMPRESA_LISTAR,
	URI_EMPRESA_MOSTRAR,
	URI_EMPRESA_EDITAR,
	URI_WEBSITE_EMPRESA_MOSTRAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getEmpresas ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_EMPRESA_LISTAR)
		.then(response => {
			commit('SET_EMPRESAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function showEmpresa ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_EMPRESA_MOSTRAR)
		.then(response => {
			commit('SET_EMPRESA', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function updateEmpresa ({ dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('empresaID', data.empresa_id)
        formData.append('nombre', ecode.encode(data.nombre))
        formData.append('propietario', ecode.encode(data.propietario))
        formData.append('direccion', ecode.encode(data.direccion))
        formData.append('telefono', ecode.encode(data.telefono))
        formData.append('celular', ecode.encode(data.celular))
        formData.append('correo', ecode.encode(data.correo))
        formData.append('horario', ecode.encode(data.horario))
        formData.append('ciudad', ecode.encode(data.ciudad))
		formData.append('link_facebook', ecode.encode(data.link_facebook))
		formData.append('link_instagram', ecode.encode(data.link_instagram))
		formData.append('link_web', ecode.encode(data.link_web))
		formData.append('slogan', ecode.encode(data.slogan))
		formData.append('descripcion', ecode.encode(data.descripcion))
		formData.append('color', ecode.encode(data.color))

        if (data.file) {
            formData.append('file', data.file.filter(x => typeof x !== 'undefined').shift())
        }

		if (data.file_horizontal) {
            formData.append('file_horizontal', data.file_horizontal.filter(x => typeof x !== 'undefined').shift())
        }

		axios.post(URI_EMPRESA_EDITAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export async function showEmpresaWebsite ({ commit }, data) {
	const response = await axios.get(URI_WEBSITE_EMPRESA_MOSTRAR)
	if (response.status === 200) {
		commit('SET_EMPRESA', response.data.data)
		return response
	}
}