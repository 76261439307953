import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_MARCA_LISTAR,
	URI_MARCA_GUARDAR,
	URI_MARCA_EDITAR,
	URI_MARCA_ELIMINAR,
	URI_MARCA_PAGINATE,
	URI_WEBSITE_MARCA_LISTAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginateMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_MARCA_PAGINATE, formData)
		.then(response => {
			commit('SET_MARCAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_MARCA_LISTAR)
		.then(response => {
			commit('SET_MARCAS', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombreMarca))

		axios.post(URI_MARCA_GUARDAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function editarMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('marcaID', data.id)
		formData.append('nombre', ecode.encode(data.nombreMarca))

		axios.post(URI_MARCA_EDITAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteMarca ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('marcaID', id)
		
		axios.post(URI_MARCA_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

/** WEBSITE */

export function getWebsiteMarca ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_WEBSITE_MARCA_LISTAR)
		.then(response => {
			commit('SET_MARCAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}