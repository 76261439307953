export function SET_USUARIOS (state, data) {
	state.usuarios = data
}

export function SET_USUARIOS_TEMPORAL (state, data) {
	state.usuariosTemporal = data
}

export function SET_ROLES (state, data) {
	state.roles = data
}

export function SET_USUARIOS_FIND (state, data) {
	state.usuariosFind = data
}
