import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_CONFIGURACION_DASHBOARD,
	URI_CONFIGURACION_SISTEMA,
	URI_CONFIGURACION_SISTEMA_ENUM_EDITAR,
	URI_CONFIGURACION_SISTEMA_NUMERICO_EDITAR,
	URI_CONFIGURACION_SISTEMA_INICIALIZACION
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export async function getConfiguraciones ({ commit }) {
	try {
		const response = await axios.get(URI_CONFIGURACION_DASHBOARD)
		if (response.status === 200) {
			commit('SET_CONFIG', response.data.config)
			return response
		}
	} catch (error) {
		return error
	}
}

export async function getParametroSistema ({ commit }) {
	try {
		const response = await axios.get(URI_CONFIGURACION_SISTEMA)
		if (response.status === 200) {
			commit('SET_CADENA', response.data.cadena)
			commit('SET_ENUM', response.data.enum)
			commit('SET_NUMERICO', response.data.numerico)
			return response
		}
	} catch (error) {
		return error
	}
}

export async function updateConfig ({ dispatch, commit }, data) {
	const formData = new FormData()
	formData.append('configuracionID', data.configuracionID)
	formData.append('data_enum', data.newState)
	const response = await axios.post(URI_CONFIGURACION_SISTEMA_ENUM_EDITAR, formData)
	if (response.status === 201) {
		dispatch('getParametroSistema')
		dispatch('getConfiguraciones')
		return response
	}
}

export async function updateConfigNumerico ({ dispatch, commit }, data) {
	const formData = new FormData()
	formData.append('configuracionID', data.configuracionID)
	formData.append('data_numerico', data.value)
	const response = await axios.post(URI_CONFIGURACION_SISTEMA_NUMERICO_EDITAR, formData)
	if (response.status === 201) {
		dispatch('getParametroSistema')
		dispatch('getConfiguraciones')
		return response
	}
}

export async function registrarConfiguracionInicio ({ dispatch, commit }, data) {
	const formData = new FormData()
	formData.append('estado', 'TERMINADO')
	const response = await axios.post(URI_CONFIGURACION_SISTEMA_INICIALIZACION, formData)
	if (response.status === 201) {
		dispatch('checkUserMain')
		return response
	}
}