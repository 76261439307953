export function config (state) {
	return state.config
}

export function paramCadena (state) {
	return state.paramCadena
}

export function paramEnum (state) {
	return state.paramEnum
}

export function paramNumerico (state) {
	return state.paramNumerico
}