export function SET_SCROLL_PRODUCTOS (state, data) {
	state.scrollProductos = data
}

export function SET_SCROLL_PRODUCTOS_STOCK (state, data) {
	state.scrollProductosStock = data
}

export function SET_PRODUCTOS (state, data) {
	state.productos = data
}

export function SET_LISTA_PRODUCTOS (state, data) {
	state.listaProductos = data
}

export function SET_LISTA_PRODUCTO_PROVEEDOR (state, data) {
	state.listaProductoProveedor = data
}