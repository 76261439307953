export function scrollProductos (state) {
	return state.scrollProductos
}

export function scrollProductosStock (state) {
	return state.scrollProductosStock
}

export function productos (state) {
	return state.productos
}

export function listaProductos (state) {
	return state.listaProductos
}

export function listaProductoProveedor (state) {
	return state.listaProductoProveedor
}
