import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_SUBCATEGORIA_LISTAR,
	URI_SUBCATEGORIA_GUARDAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getSubCategoria ({ dispatch, commit }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('categoriaID', id)

		axios.post(URI_SUBCATEGORIA_LISTAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_SUBCATEGORIAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function create ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('categoriaID', data.categoriaID)
		formData.append('nombre', ecode.encode(data.nombreSubCategoria))

		axios.post(URI_SUBCATEGORIA_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}