import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/config/store'
import router from '@/config/router'
import * as utils from '@/utils/utils'
import { 
	TOKEN, 
	USER_ID,
	ALMACEN_SISTEMA_KEY_ID,
	SISTEMA_USUARIO
} from '../../utils/constants'

axios.defaults.baseURL = utils.getEndPointSistema()

// Response interceptor
axios.interceptors.response.use(response => {
    return response
}, (error) => {
    const status = error.response.status
    switch (status) {
		case 401:
			utils.openNotificationWithIcon('warning', 'Mensaje : ', 'La sesion ha finalizado debe volver a iniciar sesion en el sistema...', 'topRight')
			setTimeout(function () {
				Cookies.remove(ALMACEN_SISTEMA_KEY_ID)
				Cookies.remove(SISTEMA_USUARIO)
				Cookies.remove(USER_ID)
				Cookies.remove(TOKEN)
				sessionStorage.removeItem(TOKEN)
				window.location.href = '/panel/controladmin/access/efectivo'
			}, 1200)
        break
		case 404:
			console.log('404')
        break
      	case 422:
			console.log('422')
        break
      	case 403:
			console.log('403')
        break
		case 409:
			console.log('409')
        break
		case 429:
			utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Ha realizado demasiadas peticiones al Servidor...', 'topRight')
		break
      	case 500:
			console.log('500')
        break
      	default:
			console.log('verificar estado sistema')
        break
    }
    return Promise.reject(error)
})

export default axios