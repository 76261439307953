export function SET_ALMACEN (state, data) {
	state.almacen = data
}

export function SET_ALMACENES (state, data) {
	state.almacenes = data
}

export function SET_LISTA_ALMACENES (state, data) {
	state.listaAlmacenes = data
}