export function SET_TIPO_COMPRAS (state, data) {
	state.tipodecompras = data
}

export function SET_TIPO_VENTAS (state, data) {
	state.tipodeventas = data
}

export function SET_TIPO_INGRESOS (state, data) {
	state.tipodeingresos = data
}

export function SET_TIPO_EGRESOS (state, data) {
	state.tipodegastos = data
}