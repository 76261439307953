export function SET_ARQUEO_CAJAS (state, data) {
	state.arqueocajas = data
}

export function SET_ARQUEO_LISTADO (state, data) {
	state.listado = data
}

export function SET_REPORTE (state, data) {
	state.reporte = data
}