export function tipodeventas (state) {
	return state.tipodeventas
}

export function tipodecompras (state) {
	return state.tipodecompras.map((value) => {
		if (value.plancuenta_id === '1') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/money.svg' : '/static/assets/images/menu/money.svg'
		} else if (value.plancuenta_id === '2') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/invoice-tree.svg' : '/static/assets/images/menu/invoice-tree.svg'
		} else if (value.plancuenta_id === '3') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/credit_card.png' : '/static/assets/images/menu/credit_card.png'
		} else if (value.plancuenta_id === '4') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/library.svg' : '/static/assets/images/menu/library.svg'
		} else if (value.plancuenta_id === '6') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/money_transfer.svg' : '/static/assets/images/menu/money_transfer.svg'
		} else if (value.plancuenta_id === '7') {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/vales.png' : '/static/assets/images/menu/vales.png'
		} else if (value.plancuenta_id === '11') {
			value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/debt-list.svg' : '/static/assets/images/menu/debt-list.svg'
	  	} else {
		  	value.imagen = (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/dollar.png' : '/static/assets/images/menu/dollar.png'
		}
		
		return value
	})
}

export function tipodeingresos (state) {
	return state.tipodeingresos
}

export function tipodegastos (state) {
	return state.tipodegastos
}