import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_AJUSTEINGRESO_PAGINAR,
	URI_AJUSTEINGRESO_REPORTE,
	URI_AJUSTEINGRESO_DATOS_AGREGAR,
	URI_AJUSTEINGRESO_GUARDAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacenID)
		formData.append('usuario', data.usuario)
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', ecode.encode(data.keyword))
    	formData.append('paginate', ecode.encode(JSON.stringify(data)))

		axios.post(URI_AJUSTEINGRESO_PAGINAR, formData)
		.then(response => {
			commit('SET_PAGINATE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporteAjusteIngreso ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('ajusteingresoID', data)
		
		axios.post(URI_AJUSTEINGRESO_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getDatosAjuste ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('tipoingresoID', 4)
		formData.append('almacenID', data.almacen_id)
		
		axios.post(URI_AJUSTEINGRESO_DATOS_AGREGAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarAjuste ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tipoingreso_id', 4)
		formData.append('plancuenta_id', parseInt(data.plancuenta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : data.usuario)
		formData.append('proveedor_id', parseInt(data.proveedor_id))
		formData.append('tipocambio', 0)
		formData.append('observaciones', ecode.encode(data.observaciones))

		formData.append('subTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('montoTotal', ecode.encode(data.importeTotal.toString()))
		formData.append('descuentoMoneda', ecode.encode(data.descuentoMonedaMonto.toString()))
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))
		
		axios.post(URI_AJUSTEINGRESO_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}