export function plantillaImportacion (state) {
	return state.plantillaImportacion
}

export function inventarios (state) {
	return state.inventarios
}

export function informacionSaldo (state) {
	return state.informacionSaldo
}