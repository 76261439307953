import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	MODULO,
	URI_ROLE_LISTAR,
	URI_USUARIO_PAGINAR,
	URI_USUARIO_LISTAR,
	URI_USUARIO_GUARDAR,
	URI_USUARIO_ACTUALIZAR,
	URI_USUARIO_ELIMINAR,
	URI_USUARIO_PAGINAR_TEMPORAL,
	URI_USUARIO_TEMPORAL_REESTABLECER,
	URI_USUARIO_ELIMINAR_PERMANENTE
} from '../../../../utils/constants'
import * as utils from '@/utils/utils'

export function getUsuarios ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_USUARIO_PAGINAR, formData)
		.then(response => {
			commit('SET_USUARIOS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getUsuariosTemporal ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_USUARIO_PAGINAR_TEMPORAL, formData)
		.then(response => {
			commit('SET_USUARIOS_TEMPORAL', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getUsuarioListar ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_USUARIO_LISTAR)
		.then(response => {
			commit('SET_USUARIOS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getRoles ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_ROLE_LISTAR)
		.then(response => {
			commit('SET_ROLES', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function createUsuario ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const roles = data.roles.map((item, index) => {
			if (item === true) {
				return { id: index }
			}
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombre))
		formData.append('usuario', ecode.encode(data.usuario))
		formData.append('email', data.email)
		formData.append('telefono', ecode.encode(data.telefono))
		formData.append('direccion', ecode.encode(data.direccion))
		formData.append('password', data.password)
		formData.append('estado', ecode.encode(data.estado))
		formData.append('almacenID', ecode.encode(data.almacenID))
		formData.append('roles', ecode.encode(JSON.stringify(utils.eliminateUndefined(roles))))

		axios.post(URI_USUARIO_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_USUARIOS', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function actualizarUsuario ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const roles = Object.keys(data.roles).map(Number).map(clave => {
			if (data.roles[clave] === true) {
				return { id: clave }
			}
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('usuarioID', data.usuarioID)
		formData.append('nombre', ecode.encode(data.nombre))
		formData.append('usuario', ecode.encode(data.usuario))
		formData.append('email', data.email)
		formData.append('telefono', ecode.encode(data.telefono))
		formData.append('direccion', ecode.encode(data.direccion))
		formData.append('password', (data.password === '' || data.password === null) ? '' : data.password)
		formData.append('estado', ecode.encode(data.estado))
		formData.append('almacenID', ecode.encode(data.almacenID))
		formData.append('roles', ecode.encode(JSON.stringify(utils.eliminateUndefined(roles))))

		axios.post(URI_USUARIO_ACTUALIZAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_USUARIOS', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteUsuario ({ dispatch, commit }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('usuarioID', id)
		
		axios.post(URI_USUARIO_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_USUARIOS', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function reestablecer ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const usuarios = data.map((item, index) => {
			return { usuarioID: item }
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('usuarios', ecode.encode(JSON.stringify(utils.eliminateUndefined(usuarios))))
		
		axios.post(URI_USUARIO_TEMPORAL_REESTABLECER, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_USUARIOS_TEMPORAL', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function eliminarPermanente ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const usuarios = data.map((item, index) => {
			return { usuarioID: item }
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('usuarios', ecode.encode(JSON.stringify(utils.eliminateUndefined(usuarios))))
		
		axios.post(URI_USUARIO_ELIMINAR_PERMANENTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_USUARIOS_TEMPORAL', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}