import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import Cookies from 'js-cookie'
import { 
	URI_INVENTARIO_SALDO,
	URI_INVENTARIO_LISTA_PRODUCTO,
	URI_INVENTARIO_IMPORTACION_EXCEL,
	URI_INVENTARIO_DESCARGAR_PLANTILLA
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getInformacion ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)

		axios.post(URI_INVENTARIO_SALDO, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_INFORMACION_SALDO', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getListaInventario ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)
        formData.append('proveedorID', (data.proveedor_id === 'Seleccionar') ? 0 : data.proveedor_id)
		formData.append('keyword', (data.keyword === '') ? '' : ecode.encode(data.keyword))
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_INVENTARIO_LISTA_PRODUCTO, formData)
		.then(response => {
			setTimeout(() => {
				commit('SET_INVENTARIOS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function importacionExcel ({ commit, dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)
		formData.append('file', data.importacionExcel.filter(x => typeof x !== 'undefined').shift())

		axios.post(URI_INVENTARIO_IMPORTACION_EXCEL, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function plantillaImportacionExcel ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_INVENTARIO_DESCARGAR_PLANTILLA)
		.then(response => {
			commit('SET_PLANTILLA_IMPORTACION', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}