import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_PRECIOVENTA_ACTUALIZAR_ESTADO
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function updateStatePrecioVenta ({ dispatch }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('precioventas', ecode.encode(JSON.stringify(data)))

		axios.post(URI_PRECIOVENTA_ACTUALIZAR_ESTADO, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}
