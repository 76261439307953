import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_CATEGORIA_LISTAR,
	URI_CATEGORIA_PAGINATE,
	URI_CATEGORIA_GUARDAR,
	URI_CATEGORIA_EDITAR,
	URI_CATEGORIA_ELIMINAR,
	URI_WEBSITE_CATEGORIA_LISTAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginateCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_CATEGORIA_PAGINATE, formData)
		.then(response => {
			commit('SET_CATEGORIAS', [])
			setTimeout(() => {
				commit('SET_CATEGORIAS', response.data.data)
				resolve(response)
			}, 300)
		}).catch(error => reject(error))
	})
}

export function getCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_CATEGORIA_LISTAR)
		.then(response => {
			commit('SET_CATEGORIAS', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombre))

		axios.post(URI_CATEGORIA_GUARDAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function editarCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('categoriaID', data.id)
		formData.append('nombre', ecode.encode(data.nombre))

		axios.post(URI_CATEGORIA_EDITAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteCategoria ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('categoriaID', id)
		
		axios.post(URI_CATEGORIA_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

/** WEBSITE */

export function getWebsiteCategoria ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_WEBSITE_CATEGORIA_LISTAR)
		.then(response => {
			commit('SET_LISTA_CATEGORIAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}