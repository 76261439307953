export default function () {
	return {
		reportemensual: [],
		reportediariousuario: [],
		reportegrafico: [],
		reporteVenta: [],
		reporteCompra: [],
		reporteInventario: [],
		reporteInventarioExcel: [],
		reporteVentaExcel: [],
		reporteCompraExcel: []
	}
}
