export function almacenes (state) {
	return state.almacenes
}

export function listaAlmacenes (state) {
	return state.listaAlmacenes
}

export function almacen (state) {
	return state.almacen
}