import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_COMPRA_REPORTE,
	URI_COMPRA_DATOS,
	URI_COMPRA_GUARDAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getReporteCompra ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('compraID', data)
		
		axios.post(URI_COMPRA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getDatosCompra ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('tipoingresoID', 2)
		formData.append('almacenID', data.almacen_id)
		
		axios.post(URI_COMPRA_DATOS, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarCompra ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()

		formData.append('almacen_id', parseInt(data.almacen_id))
		formData.append('tipoingreso_id', 2)
		formData.append('plancuenta_id', parseInt(data.plancuenta_id))
		formData.append('usuario', (data.usuario === 'Seleccionar') ? '' : data.usuario)
		formData.append('proveedor_id', parseInt(data.proveedor_id))
		formData.append('tipocambio', 0)

		formData.append('observaciones', ecode.encode(data.observaciones))
		formData.append('fechaCredito', ecode.encode(data.fechaCredito))
		formData.append('fecha_compra', ecode.encode(data.fechadecompra))
		formData.append('nroDocumento', (data.nrodocumento) ? '' : parseInt(data.nrodocumento))

		formData.append('razonSocial', '')
        formData.append('autorizacion', '')
        formData.append('controlCodigo', '')
        formData.append('descuentos', '')
        formData.append('factura', '')
        formData.append('ice', '')
        formData.append('valores_exceptos', '')

		formData.append('subTotal', ecode.encode(data.subtotal.toString()))
		formData.append('descuento', ecode.encode(data.descuento.toString()))
		formData.append('montototal', ecode.encode(data.importeTotal.toString()))
		formData.append('descuentoMoneda', ecode.encode(data.descuentoMonedaMonto.toString()))
		
		formData.append('productos', ecode.encode(JSON.stringify(data.productos)))

		if (Object.entries([]).length > 0) {
			formData.append('tipocambioValor', [])
		}
		
		axios.post(URI_COMPRA_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}
