import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_TRANSFERENCIA_CONSULTA_PROCESO_ORIGEN,
	URI_TRANSFERENCIA_CONFIRMAR,
	URI_TRANSFERENCIA_ANULAR,
	URI_TRANSFERENCIA_REPORTE
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getReporteTraspaso ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('transferenciaID', data)
		
		axios.post(URI_TRANSFERENCIA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getTransferenciaPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacen_origenID', data.almacen_origenID)
    	formData.append('almacen_destinoID', (data.almacen_destinoID === 'Seleccionar') ? '' : data.almacen_destinoID)
    	formData.append('usuarioOrigenID', (data.usuarioOrigenID === 'Seleccionar') ? '' : data.usuarioOrigenID)
		formData.append('usuarioDestinoID', (data.usuarioDestinoID === 'Seleccionar') ? '' : data.usuarioDestinoID)
    	formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', ecode.encode(data.keyword))
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_TRANSFERENCIA_CONSULTA_PROCESO_ORIGEN, formData)
		.then(response => {
			commit('SET_TRANSFERENCIAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function confirmar ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('transferenciaID', id)
		
		axios.post(URI_TRANSFERENCIA_CONFIRMAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function anular ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('transferenciaID', id)
		
		axios.post(URI_TRANSFERENCIA_ANULAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}