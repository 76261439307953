const DB_NAME = 'efectivoDB'
const DB_VERSION = 1
const STORE_NAME = 'productos'

// Function to open or create an IndexedDB database
export async function openDatabase () {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION)
        request.onerror = () => reject('Error al abrir la base de datos')
        request.onsuccess = () => resolve(request.result)
        request.onupgradeneeded = (event) => {
            const db = event.target.result
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true })
                console.log(`Object store ${STORE_NAME} creada`)
            }
        }
    })
}

export async function setData (key, array) {
    const db = await openDatabase()
    if (!db.objectStoreNames.contains(STORE_NAME)) {
        throw new Error(`Object store ${STORE_NAME} no encontrada`)
    }
    const transaction = db.transaction(STORE_NAME, 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    try {
        await store.put({ key: key, value: array })
        console.log('SET_DATA almacenando en IndexedDB')
    } catch (error) {
        console.error('Error al almacenar la Data en IndexedDB:', error)
    }
}

export async function getData (key) {
    const db = await openDatabase()
    if (!db.objectStoreNames.contains(STORE_NAME)) {
        throw new Error(`Object store ${STORE_NAME} no encontrada`)
    }
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readonly')
        const store = transaction.objectStore(STORE_NAME)
        const request = store.openCursor()
        request.onsuccess = (event) => {
            const cursor = event.target.result
            if (cursor) {
                const data = cursor.value
                if (data.key === key) {
                    console.log('GET_DATA obteniendo de IndexedDB')
                    resolve(data.value)
                } else {
                    cursor.continue()
                }
            } else {
                resolve(null) // No se encontró ningún objeto con la clave proporcionada
            }
        }
        request.onerror = () => reject('Error al obtener el array de IndexedDB')
    })
}

export async function removeData (key) {
    const db = await openDatabase()
    if (!db.objectStoreNames.contains(STORE_NAME)) {
        throw new Error(`Object store ${STORE_NAME} no encontrada`)
    }
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readwrite')
        const store = transaction.objectStore(STORE_NAME)
        const request = store.openCursor()
        request.onsuccess = (event) => {
            const cursor = event.target.result
            if (cursor) {
                const data = cursor.value
                if (data.key === key) {
                    cursor.delete() // Elimina el objeto con la clave proporcionada
                    console.log('DELETE_DATA eliminado de IndexedDB')
                    resolve()
                } else {
                    cursor.continue()
                }
            } else {
                resolve() // No se encontró ningún objeto con la clave proporcionada
            }
        }
        request.onerror = () => reject('Error al eliminar el dato de IndexedDB')
    })
}

// Function to delete the IndexedDB database
export async function deleteDatabase () {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(DB_NAME)
        request.onsuccess = () => {
            console.log('Base de datos eliminada con éxito')
            resolve()
        }
        request.onerror = (event) => {
            console.error('Error al eliminar la base de datos:', event.target.error)
            reject('Error al eliminar la base de datos')
        }
        request.onblocked = () => {
            console.warn('La eliminación de la base de datos está bloqueada')
            reject('La eliminación de la base de datos está bloqueada')
        }
    })
}

// Function to delete the object store
export async function deleteObjectStore () {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION)
        request.onerror = (event) => {
            console.error('Error al abrir la base de datos:', event.target.error)
            reject('Error al abrir la base de datos')
        }
        request.onupgradeneeded = (event) => {
            const db = event.target.result
            if (db.objectStoreNames.contains(STORE_NAME)) {
                db.deleteObjectStore(STORE_NAME)
                console.log(`Object store ${STORE_NAME} eliminada`)
            }
        }
        request.onsuccess = (event) => {
            event.target.result.close()
            resolve()
        }
    })
}