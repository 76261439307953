import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	MODULO,
	URI_ROLE_PAGINAR,
	URI_PERMISO_LISTAR,
	URI_ROLE_GUARDAR,
	URI_ROLE_ACTUALIZAR,
	URI_ROLE_ELIMINAR
} from '../../../../utils/constants'
import * as utils from '@/utils/utils'

export function getRoles ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_ROLE_PAGINAR, formData)
		.then(response => {
			commit('SET_ROLES', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getPermission ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_PERMISO_LISTAR)
		.then(response => {
			commit('SET_PERMISOS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function createRole ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const permisos = data.respuesta.map((item, index) => {
			if (item === true) {
				return { id: index }
			}
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombre))
		formData.append('permisos', ecode.encode(JSON.stringify(utils.eliminateUndefined(permisos))))
		axios.post(URI_ROLE_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_ROLES', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function updateRole ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const permisos = Object.keys(data.respuesta).map(Number).map(clave => {
			if (data.respuesta[clave] === true) {
				return { id: clave }
			}
		})

		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('roleID', data.id)
		formData.append('nombre', ecode.encode(data.nombre))
		formData.append('permisos', ecode.encode(JSON.stringify(utils.eliminateUndefined(permisos))))

		axios.post(URI_ROLE_ACTUALIZAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_ROLES', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteRole ({ dispatch, commit }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('roleID', id)
		
		axios.post(URI_ROLE_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_ROLES', [])
			resolve(response)
		}).catch(error => reject(error))
	})
}