import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_TIPOPRECIOVENTA_PAGINAR,
	URI_TIPOPRECIOVENTA_GUARDAR,
	URI_TIPOPRECIOVENTA_ACTUALIZAR,
	URI_TIPOPRECIOVENTA_ELIMINAR,
	URI_TIPOPRECIOVENTA_LISTAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
    	formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_TIPOPRECIOVENTA_PAGINAR, formData)
		.then(response => {
			commit('SET_TIPO_PRECIO_VENTAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getTipoPrecioVenta ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_TIPOPRECIOVENTA_LISTAR)
		.then(response => {
			commit('SET_LISTADO_TIPO_PRECIO_VENTAS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardar ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('nombre', ecode.encode(data.nombre))

		axios.post(URI_TIPOPRECIOVENTA_GUARDAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function actualizar ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('tipoprecioventaID', data.id)
		formData.append('nombre', ecode.encode(data.nombre))

		axios.post(URI_TIPOPRECIOVENTA_ACTUALIZAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function eliminar ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('tipoprecioventaID', id)
		
		axios.post(URI_TIPOPRECIOVENTA_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}