import axios from '@/config/axios/axios'
import store from '@/config/store'

store.subscribe((mutation) => {
	switch (mutation.type) {
		case 'auth/SET_TOKEN':
		if (mutation.payload) {
			axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`
		} else {
			axios.defaults.headers.common.Authorization = null
		}
		break
	}
})
