export function SET_REPORTE_VENTA (state, data) {
	state.reporteVenta = data
}

export function SET_REPORTE_COMPRA (state, data) {
	state.reporteCompra = data
}

export function SET_REPORTE_INVENTARIO (state, data) {
	state.reporteInventario = data
}

export function SET_REPORTE_INVENTARIO_EXCEL (state, data) {
	state.reporteInventarioExcel = data
}

export function SET_REPORTE_VENTA_EXCEL (state, data) {
	state.reporteVentaExcel = data
}

export function SET_REPORTE_COMPRA_EXCEL (state, data) {
	state.reporteCompraExcel = data
}

export function SET_REPORTE_DIARIO_USUARIO (state, data) {
	state.reportediariousuario = data
}

export function SET_REPORTE_MENSUAL (state, data) {
	state.reportemensual = data
}

export function SET_REPORTE_GRAFICO (state, data) {
	state.reportegrafico = data
}