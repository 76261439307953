import { notification } from 'ant-design-vue'
import moment from 'moment'

export function getEndPointSistema () {
	if (process.env.NODE_ENV === 'production') {
		const hostname = window.location.hostname
		const url = `https://${hostname}` // https://${hostname} Produccion
		return url
	} else {
		return process.env.VUE_APP_API_ENDPOINT
	}
}

function getRandomChar (stack, cantidad) {
	var choice = ''
	for (var i = 0; i < cantidad; i++) {
		var indice = Math.floor((Math.random() * (stack.length - 1)) + 1)
		choice += stack[indice]
	}
	return choice
}

export function openNotificationWithIcon (type, title, message, placement) {
	var data = ''
	function myFunction (item) {
		var TblRow = ''
		TblRow += item + '<br>'
		return TblRow
	}
	if (typeof message === 'object') {
		data = Object.values(message)
		data.forEach(myFunction)
	} else if (typeof message === 'string') {
		data = message
	}	
	notification[type]({
		message: title,
		description: data,
		placement: placement,
		top: '60px'
	})
}

export function showErrors (data) {
	if (typeof data.errors === 'object') {
		const infoObject = data.errors
		const errors = []
		for (const key in infoObject) {
			const error = infoObject[key]
			for (const msg of error) {
				errors.push(msg)
			}
		}
		return errors
	}

	if (typeof data.message === 'string') {
		return data.message
	}

	if (typeof data === 'string') {
		return data
	}
}

export function generatePassword (tamanio, tipo) {
	let carateres = ''
	switch (tipo) {
		case 'num':
			carateres = '0123456789'
		break
		case 'alf':
			carateres = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
		break
		case 'rand':
		break
		default:
			carateres = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
		break
	}

	let pass = ''
	for (let i = 0; i < tamanio; i++) {
		if (tipo === 'rand') {
			pass += String.fromCharCode((Math.floor((Math.random() * 100)) % 94) + 33)
		} else {
			pass += carateres.charAt(Math.floor(Math.random() * carateres.length))
		}
	}
	return pass
}

export function generarPasswordForte () {
    const raritos = ['@', '#', '$', '-', '_']
    const minusculas = 'abcdefghijklmnopqrstuvwxyz'
    const mayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numeros = '0123456789'

    function getRandomChar (array, cantidad) {
        let result = ''
        for (let i = 0; i < cantidad; i++) {
            const randomIndex = Math.floor(Math.random() * array.length)
            result += array[randomIndex]
        }
        return result
    }

    // Construir la contraseña según los criterios de Laravel
    let password = getRandomChar(minusculas, 6)
    password += getRandomChar(raritos, 1)
    password += getRandomChar(mayusculas, 1)
    password += getRandomChar(numeros, 2)

    // Mezclar aleatoriamente la contraseña
    password = password.split('').sort(() => Math.random() - 0.5).join('')

    return password
}

export function formatMoney (amount, decimalCount = 2, decimal = '.', thousands = '') {
	try {
		decimalCount = Math.abs(decimalCount)
	  	decimalCount = isNaN(decimalCount) ? 2 : decimalCount
		const negativeSign = amount < 0 ? '-' : ''
	  
		const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
		const j = (i.length > 3) ? i.length % 3 : 0
	  
		const number = negativeSign +
			(j ? i.substr(0, j) + thousands : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
			(decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')

		return number
	} catch (e) {
	  console.log(e)
	}
}

export function formatDate (value) {
	return moment(value).format('DD/MM/YYYY h:mm:ss A')
}

export function formatOnlyDate (value) {
	return moment(value).format('DD/MM/YYYY')
}

export function getDateFormat (date) {
	const d = moment(date)
	return date && d.isValid() ? d : ''
}

export function quitarPuntosYCapitalizar (cadena) {
	// Primero, quitamos los puntos y los reemplazamos por espacios
	const cadenaSinPuntos = cadena.replace(/\./g, ' ')
  
	// Luego, dividimos la cadena en palabras
	const palabras = cadenaSinPuntos.split(' ')
  
	// Luego, capitalizamos cada palabra
	const palabrasCapitalizadas = palabras.map(palabra => {
	  // Asegurémonos de que la palabra no esté vacía
	  if (palabra.length > 0) {
		// Capitalizamos la primera letra y convertimos el resto a minúsculas
		return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
	  }
	  return palabra
	})

	// Finalmente, unimos las palabras capitalizadas en una cadena
	const cadenaFinal = palabrasCapitalizadas.join(' ')
  
	return cadenaFinal
}

export const eliminateUndefined = arr => {
	for (let i = 0; i < arr.length;) {
		if (typeof arr[i] !== 'undefined') {
			i++
			continue
		}
		arr.splice(i, 1)
	}
	return arr
}

export const flattenArray = arr => {
	return arr.reduce((acc, current) => {
	  	if (Array.isArray(current)) {
			// Si el elemento actual es un array, llamamos a la función de aplanamiento recursivamente
			acc.push(...flattenArray(current))
	  	} else {
			// Si el elemento actual no es un array, lo agregamos al resultado
			acc.push(current)
	  	}
	  	return acc
	}, [])
}

export const decodeHTMLEntities = str => {
	return str.replace(/&#(\d+);/g, function (match, dec) {
		return String.fromCharCode(dec)
	})
}

export function truncarCadenaConPalabraCompleta (cadena, tamano, elipses) {
	if (cadena.length <= tamano) {
		return cadena
	}
	const elipse = elipses || '...'
	var caracteres = 0
	var arreglo = cadena.split(' ')
	for (var i in arreglo) {
		caracteres += arreglo[i].length
		if (caracteres > tamano) {
		arreglo.splice(i, arreglo.length)
		}
		caracteres++
	}
	return arreglo.join(' ') + elipse
}

export function removeHtmlTagFromString (strHtmlCodes) {
	const strHtmlCode = strHtmlCodes.replace(/&(lt|gt);/g,
	function (strMatch, p1) {
		return (p1 === 'lt') ? '<' : '>'
	})
	return strHtmlCode.replace(/<\/?[^>]+(>|$)/g, '')
}

export function getCleanNumbersInString (string) {
	var tmp = string.split('')
	var map = tmp.map(function (current) {
		if (!isNaN(parseInt(current))) {
		return current
		}
	})
	var numbers = map.filter(function (value) {
		return value !== undefined
	})
	return numbers.join('')
}

export function onlyNumber ($event) {
	const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
		$event.preventDefault()
	}
}

export function redondearNumero (num, precision = 2) {
	const scaled = Math.round(num + 'e' + precision)
	return Number(scaled + 'e' + -precision)
}

export async function urlToBase64 (url) {
    const response = await fetch(url)
    const blob = await response.blob()
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
    })
} 

export function getRandomInterval (min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min
}

export function openReciboMobile (recurso) {
	const esMovil = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone|Kindle|Silk|Mobile/i.test(navigator.userAgent)

	if (esMovil) {
		// Si es un dispositivo móvil
		window.open(recurso, '_blank')
	} else {
		// Si es un dispositivo de escritorio
		window.open(recurso, '_blank', 'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=770, height=900')
	}
}

export function openRecibo (recurso) {
	window.open(recurso, '_blank', 'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=770, height=900')
}