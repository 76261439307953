import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_BACKUP_FORMATEAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function formatSistema ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('password', data.password)

		axios.post(URI_BACKUP_FORMATEAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}