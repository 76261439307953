import Vue from 'vue'
import './main.css'
import App from './App.vue'
import Cookies from 'js-cookie'
import router from './config/router'
import store from './config/store'
import { TOKEN, USER_ID } from './utils/constants'
import Vuelidate from 'vuelidate'
import { abilitiesPlugin } from '@casl/vue'
import ability from './services/ability'
import LoadScript from 'vue-plugin-load-script'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.config.productionTip = false

Vue.component('vue-simple-spinner', require('vue-simple-spinner'))

Vue.use(Antd)
Vue.use(LoadScript)
Vue.use(Vuelidate)
Vue.use(abilitiesPlugin, ability)
Vue.use(VueTour)
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
require('./config/subscriber')

const token = Cookies.get(TOKEN) || sessionStorage.getItem(TOKEN)

store.dispatch('auth/verifiedAttempt', token)
.then(() => {
	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
})