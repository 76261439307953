export function SET_TOKEN (state, token) {
	state.token = token
}

export function SET_USER (state, data) {
	state.user = data
}

export function SET_STATUS (state, status) {
	state.status = status
}
