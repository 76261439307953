export function SET_CONFIG (state, data) {
	state.config = data
}

export function SET_CADENA (state, data) {
	state.paramCadena = data
}

export function SET_ENUM (state, data) {
	state.paramEnum = data
}

export function SET_NUMERICO (state, data) {
	state.paramNumerico = data
}

export function SET_DATA_ENUM (state, { configuracionID, newState }) {
    const item = state.paramEnum.find(item => item.configuracion_id === configuracionID)
    if (item) {
      	item.data_enum = newState
    }
}

export function SET_DATA_NUMERICO (state, { configuracionID, newValue }) {
	const item = state.paramNumerico
					    .flatMap(item => item.configuraciones)
		                .find(item => item.configuracion_id === configuracionID)
	if (item) {
		item.data_numerico = newValue
	}
}