import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_CUENTACOBRAR_REPORTE
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getReporteCuentaCobrar ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('cuentacobrarID', data)
		
		axios.post(URI_CUENTACOBRAR_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.cuenta)
			commit('SET_HISTORIAL', response.data.lista)
			resolve(response)
		}).catch(error => reject(error))
	})
}