import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_INGRESOCAJA_GUARDAR,
	URI_INGRESOCAJA_REPORTE,
	URI_INGRESOCAJA_PAGINAR,
	URI_INGRESOCAJA_ELIMINAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)
		formData.append('usuarioID', data.usuario_id)
		formData.append('fecha_inicio', ecode.encode(data.fecha_inicio))
    	formData.append('fecha_final', ecode.encode(data.fecha_final))
		formData.append('keyword', ecode.encode(data.keyword))
    	formData.append('paginate', ecode.encode(JSON.stringify(data)))

		axios.post(URI_INGRESOCAJA_PAGINAR, formData)
		.then(response => {
			commit('SET_PAGINATE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getReporte ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('ingresocajaID', data)
		
		axios.post(URI_INGRESOCAJA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function guardarIngresoCaja ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('fechaIngreso', data.fechaIngreso)
		formData.append('plancuentaID', data.plancuentaID)
		formData.append('tipocambioID', data.tipocambioID)
		formData.append('importeIngreso', ecode.encode(data.importeIngreso.toString()))
		formData.append('detalleIngreso', ecode.encode(data.detalleIngreso))
		
		axios.post(URI_INGRESOCAJA_GUARDAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function deleteIngresoCaja ({ dispatch }, id) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('ingresocajaID', id)
		
		axios.post(URI_INGRESOCAJA_ELIMINAR, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}
