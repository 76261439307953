import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import { 
	URI_TIPOCAMBIO_GET_CAMBIO
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getTipoCambios ({ commit }, data) {
	return new Promise((resolve, reject) => {
		axios.get(URI_TIPOCAMBIO_GET_CAMBIO)
		.then(response => {
			commit('SET_TIPO_CAMBIOS', response.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}