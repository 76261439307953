
export const AdminLTERoute = {
    path: '/dashboard',
    name: 'dashboard',
    redirect: '/dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../layouts/MainLayout.vue'),
    children: [
        { 
            path: '', 
            name: 'escritorio', 
            component: () => import(/* webpackChunkName: "Escritorio" */ '../pages/Escritorio/Escritorio.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/nueva/venta/:ventanaID',
            name: 'venta',
            component: () => import(/* webpackChunkName: "Venta" */ '../pages/Venta/Venta.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'consultarsalida',
            component: () => import(/* webpackChunkName: "ConsultarSalida" */ '../pages/Venta/Consulta.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/nueva/cotizacion/:ventanacotizacionID',
            name: 'nuevacotizacion',
            component: () => import(/* webpackChunkName: "Venta" */ '../pages/Cotizacion/Cotizacion.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'cotizacion',
            component: () => import(/* webpackChunkName: "Cotizacion" */ '../pages/Cotizacion/Consulta.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/ajuste/inventario/entrada',
            name: 'ajusteinventarioentrada',
            component: () => import(/* webpackChunkName: "AjusteInventarioEntrada" */ '../pages/Ajuste/AjusteEntrada.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/ajuste/inventario/salida',
            name: 'ajusteinventariosalida',
            component: () => import(/* webpackChunkName: "AjusteInventarioSalida" */ '../pages/Ajuste/AjusteSalida.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/consulta/ajuste/inventario/entrada',
            name: 'consultaajusteinventarioentrada',
            component: () => import(/* webpackChunkName: "ConsultaAjusteInventarioEntrada" */ '../pages/Ajuste/ConsultaAjusteEntrada.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/consulta/ajuste/inventario/salida',
            name: 'consultaajusteinventariosalida',
            component: () => import(/* webpackChunkName: "ConsultaAjusteInventarioSalida" */ '../pages/Ajuste/ConsultaAjusteSalida.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'cliente',
            component: () => import(/* webpackChunkName: "Cliente" */ '../pages/Cliente/Cliente.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/nueva/compra/:ventanaID',
            name: 'compra',
            component: () => import(/* webpackChunkName: "Compra" */ '../pages/Compra/Compra.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/nueva/compra-pos/:ventanaID',
            name: 'comprapos',
            component: () => import(/* webpackChunkName: "CompraPos" */ '../pages/Compra/CompraPOS.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'consultacompra',
            component: () => import(/* webpackChunkName: "ConsultaCompra" */ '../pages/Compra/Consulta.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'proveedor',
            component: () => import(/* webpackChunkName: "Proveedor" */ '../pages/Proveedor/Proveedor.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'arqueocaja',
            component: () => import(/* webpackChunkName: "ArqueoCaja" */ '../pages/ArqueoCaja/ArqueoCaja.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'consultacaja',
            component: () => import(/* webpackChunkName: "ConsultaCaja" */ '../pages/ArqueoCaja/Consulta.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'ingresocaja',
            component: () => import(/* webpackChunkName: "IngresaCaja" */ '../pages/Finanza/Modulos/ConsultaIngresoCaja.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'egresocaja',
            component: () => import(/* webpackChunkName: "EgresoCaja" */ '../pages/Finanza/Modulos/ConsultaEgresoCaja.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'catalogo',
            component: () => import(/* webpackChunkName: "Catalogo" */ '../pages/Inventario/Catalago.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'inventario',
            component: () => import(/* webpackChunkName: "Inventario" */ '../pages/Inventario/Inventario.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'notificacion',
            component: () => import(/* webpackChunkName: "NotificacionInventario" */ '../pages/Inventario/Saldo.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'movimiento',
            component: () => import(/* webpackChunkName: "Movimiento" */ '../pages/Inventario/Movimiento.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'transpaso',
            component: () => import(/* webpackChunkName: "Transpaso" */ '../pages/Inventario/Transpaso.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '',
            name: 'consultatranspaso',
            component: () => import(/* webpackChunkName: "ConsultaTranspaso" */ '../pages/Inventario/ConsultaTranspaso.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/empresa',
            name: 'empresa',
            component: () => import(/* webpackChunkName: "Empresa" */ '../pages/Empresa/EditarEmpresa.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/sucursal',
            name: 'sucursal',
            component: () => import(/* webpackChunkName: "Sucursal" */ '../pages/Sucursal/Sucursal.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/centro/reporte',
            name: 'centroreporte',
            component: () => import(/* webpackChunkName: "CentroReporte" */ '../pages/Reporte/Reporte.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/dashboard/usuario', 
            name: 'usuario', 
            component: () => import(/* webpackChunkName: "Usuario" */ '../pages/Usuario/ListaUsuario.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        { 
            path: '/dashboard/roles', 
            name: 'roles', 
            component: () => import(/* webpackChunkName: "Roles" */ '../pages/Usuario/ListaRoles.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/parametros',
            name: 'parametros',
            component: () => import(/* webpackChunkName: "Parametros" */ '../pages/Parametro/Parametro.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        },
        {
            path: '/dashboard/parametros/tipoprecioventa',
            name: 'tipodeprecioventa',
            component: () => import(/* webpackChunkName: "TipoPrecioVenta" */ '../pages/TipoPrecioVenta/TipoPrecioVenta.vue'),
            meta: {
                requiresAuth: true,
                authModule: 'auth'
            }
        }
    ]
}