import axios from '../../../axios/axios'
import { EuropioCode } from '../../../../utils/europiocode'
import Cookies from 'js-cookie'
import { 
	URI_ARQUEOCAJA_REPORTE,
	URI_ARQUEOCAJA_PAGINAR,
	URI_ARQUEOCAJA_VERIFICAR
} from '@/utils/constants'
import * as utils from '@/utils/utils'

export function getReporte ({ dispatch, commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const ecode = new EuropioCode()
		formData.append('arqueocajaID', data)
		
		axios.post(URI_ARQUEOCAJA_REPORTE, formData, { 'Content-Type': 'multipart/form-data' })
		.then(response => {
			commit('SET_REPORTE', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function controlArqueoCajaCerrada ({ commit }, almacenID) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', parseInt(almacenID))

		axios.post(URI_ARQUEOCAJA_VERIFICAR, formData)
		.then(response => {
			resolve(response)
		}).catch(error => reject(error))
	})
}

export function getArqueoCajaPaginate ({ commit }, data) {
	return new Promise((resolve, reject) => {
		const formData = new FormData()
        const ecode = new EuropioCode()
		formData.append('almacenID', data.almacen_id)
		formData.append('usuarioID', data.usuario_id)
		formData.append('operacionCaja', (data.estadoCaja === 'Seleccionar') ? '' : ecode.encode(data.estadoCaja))
        formData.append('fecha_inicio', (data.fecha_inicio) ? ecode.encode(data.fecha_inicio) : '')
        formData.append('fecha_final', (data.fecha_final) ? ecode.encode(data.fecha_final) : '')
    	formData.append('keyword', ecode.encode(data.keyword))
		formData.append('paginate', (data) ? ecode.encode(JSON.stringify(data)) : ecode.encode(JSON.stringify(this.data)))

		axios.post(URI_ARQUEOCAJA_PAGINAR, formData)
		.then(response => {
			commit('SET_ARQUEO_CAJAS', response.data.data)
			resolve(response)
		}).catch(error => reject(error))
	})
}