export function arqueocajas (state) {
	return state.arqueocajas
}

export function listado (state) {
	return state.listado
}

export function reporte (state) {
	return state.reporte
}