/*
* EuropioCode JS
*
* Codificador y decodificador de código.
*
* Codifica cadenas de texto convirtiendo caracteres no alfanuméricos en pseudo
* codigo, sanitizando así, cualquier campo de formulario previo a su
* envío. Luego, decodifica el pseudocódigo convirtiéndolo en entidades 
* hexadecimales de HTML.
* Utilizado de forma conjunta con ModSecurity y las reglas de OWASP,
* lograrán formularios invulnerables con aplicaciones 100% funcionales, gracias
* a su deodificador que interpretará el código de forma tal, que sean evitados
* los falsos positivos de ModSecurity. 
*
* EuropioCode is free software: you can redistribute it and/or modify
* it under the terms of the GNU General Public License as published by
* the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* EuropioCode is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
* GNU General Public License for more details.
* You should have received a copy of the GNU General Public License
* along with Foobar.  If not, see <http://www.gnu.org/licenses/>.
*
*
* @package    EuropioCode
* @license    http://www.gnu.org/licenses/gpl.txt  GNU GPL 3.0
* @author     Eugenia Bahit <ebahit@member.fsf.org>
* @link       http://www.europio.org
* @version    beta 1
*/

class EuropioCode {
	tabla () {
		return {
			'!': 33,
			'"': 34,
			'#': 35,
			'$': 36,
			'%': 37,
			'&': 38,
			"'": 39,
			'(': 40,
			')': 41,
			'*': 42,
			'+': 43, 
			',': 44, 
			'.': 46, 
			'/': 47,
			':': 58, 
			'<': 60, 
			'=': 61, 
			'>': 62, 
			'?': 63, 
			'@': 64, 
			'[': 91,
			'\\': 92, 
			']': 93, 
			'^': 94, 
			'_': 95, 
			'`': 96, 
			'{': 123, 
			'|': 124,
			'}': 125, 
			'~': 126, 
			'€': 128, 
			' ': 160, 
			'¡': 161, 
			'£': 163, 
			'«': 171,
			'´': 180,
			'»': 187, 
			'¿': 191, 
			'Ç': 199, 
			'ç': 231,
			'Á': 193, 
			'É': 201, 
			'Í': 205, 
			'Ó': 211, 
			'Ú': 218, 
			'Ü': 220, 
			'Ñ': 209,
			'á': 225, 
			'é': 233, 
			'í': 237, 
			'ó': 243, 
			'ú': 250, 
			'ü': 252, 
			'ñ': 241,
			'°': 176, 
			'º': 186, 
			'‘': 8216, 
			'’': 8217, 
			'‚': 8218, 
			'“': 8220,
			'”': 8221,
			'„': 8222, 
			'¼': 188, 
			'½': 189, 
			'¾': 190, 
			'¸': 184, 
			'·': 183,
			'©': 169,
			'®': 174,
			'¯': 175,
			'–': 8211,
			'——': 8212,
			'•': 8226,
			'-': 45,
			'─': 9472,
			'—': 8213,
			'−': 8722, 
			'²': 178,
			'³': 179, 
			'÷': 247, 
			'‰': 8240,
			'√': 8730, 
			'≠': 8800, 
			'⋯': 8943, 
			'⋅': 8901, 
			'∓': 8723, 
			'±': 177, 
			'≤': 8804,
			'≥': 8805,
			'α': 593, 
			'⎧': 9127,
			'⎪': 9130,
			'⎨': 9128,
			'⎩': 9129,
			'⇊': 8650,
			'​​​​​​​…​​​​': 8230,
			'ª': 170, 
			'―': 8208, 
			'‐': 818, 
			'‾': 8254, 
			'⁃': 8259,
			' ⃛': 8411,
			' ⃜': 8412,
			'‥': 8229,
			'¨': 168,
			'˙': 729,
			'\t': '09',
			'\u200e': 8206,
			'【': 12304,
			'】': 12305,
			'›': 8250,
			'\ufe0f': 65039,
			'\u2026': 8230,
			'µ': 181, 
			'˚': 730,
			'℉': 8457,
			'❤': 10084,
			'✅': 9989
		}
	}

	// prefijo utilizado en EuropioCode para la codificación de tags admitidos
    // en cadenas de texto con preformato
    preformatPrefix = 'pFt'
    // tabla de tags permitidos en textos preformateados. 
    // los hiperenlaces se manejan de forma separada. NO AGREGAR el tag <a>
    preformatTable = [
        '<b>', '<strong>', '<i>', '<em>', '<u>', 
        '<strike>', '<sub>', '<sup>',
        '<p>', '<blockquote>', '<hr>',
        '<ul>', '<ol>', '<li>',
        '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<h6>',
        '<code>', '<pre>', '<br>', '<small>'
	]

	encode (valueData) {
		var tabla = this.tabla()
        var cadenaOriginal = valueData
        var longitudCadena = cadenaOriginal.length
        var resultado = cadenaOriginal.replace(/-/g, 'ECODG45ECODC')
        resultado = resultado.replace(/;/g, 'ECODG59ECODC')
        for (var i = 0; i < longitudCadena; i++) {
            var buscar = cadenaOriginal[i]
            var codigoReemplazo = 'ECODG' + tabla[buscar] + 'ECODC'
            var reemplazo = tabla[buscar] ? codigoReemplazo : buscar
            resultado = resultado.replace(buscar, reemplazo)
        }
        resultado = resultado.replace(/\n/g, 'ECODS')
        resultado = resultado.replace(/\s/g, 'ECODG160ECODC')
        return resultado
    }

	decode (cadena, tipoSalto) {
        var breakLine = ''
        if (tipoSalto) {
			breakLine = tipoSalto
		} else {
			breakLine = '\n'
		}
		var retorno = cadena.replace(/ECODS/g, breakLine)
        retorno = retorno.replace(/ECODG/g, '&#')
        retorno = retorno.replace(/ECODC/g, ';')
        return retorno
    }

	preformat (value) {
        var textoPreformateado = value
        var cadena = this.eHyperlink(textoPreformateado)
        var longitudTabla = this.preformatTable.length
        for (var i = 0; i < longitudTabla; i++) {
            var numero = (i < 10) ? '0' + i : i

            var tagApertura = new RegExp(this.preformatTable[i], 'g')
            var pFtApertura = this.preformatPrefix + numero
            var cadenas = cadena.replace(tagApertura, pFtApertura)
            
            var tagCierreStr = this.preformatTable[i].replace('<', '</')
            var tagCierre = new RegExp(tagCierreStr, 'g')
            var pFtCierre = this.preformatPrefix + 'e' + numero
            var result = cadenas.replace(tagCierre, pFtCierre)
        } 
        this.encode(result)
    }

	/* eslint-disable no-useless-escape */
	eHyperlink (value) {
        var diccionario = {
            '>': 'fT0x1',
			'\"': '',
			'<a\ href=': 'aH0n2',
            'target=_': 'tG0n7',
			':\/\/': 'pT7n3',
			'\/': 'bB0n1',
            '\~': 'nN0n5',
			'\\.': 'p01nt',
			'\-': 'gN6n1'
        }

        var cadena = value.replace(/<\/a>/g, 'eT0n1')
        var coincidencias = cadena.match(/<a\ href=\"[a-zA-Z0-9|\.|\:|\/|-]+\"(\ target=\"_[a-z]{3,5}\")?>/g)
		var hiperenlaces = ''

		if (coincidencias) {
			hiperenlaces = coincidencias
		} else {
			hiperenlaces = []
		}

        for (var i = 0; i < hiperenlaces.length; i++) {
            var enlace = hiperenlaces[i]
            for (var etiqueta in diccionario) {
                var regex = new RegExp(etiqueta, 'g')
                enlace = enlace.replace(regex, diccionario[etiqueta])
            }
            var result = cadena.replace(hiperenlaces[i], enlace)
        }
        return result
    }
}

export { EuropioCode }